import React, { createContext, ReactNode, useContext } from 'react'
import { RootStore } from '../stores/RootStore'

// holds a reference to the store (singleton)
let store: RootStore

// create the context
const StoreContext = createContext<RootStore | undefined>(undefined)

export function useRootStore() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }

  return context
}

export function useAccountStore() {
  const { accountStore } = useRootStore()
  return accountStore
}

export function useProjectStore() {
  const { projectStore } = useRootStore()
  return projectStore
}

export function useTemplateStore() {
  const { templateStore } = useRootStore()
  return templateStore
}

export function useTemplateVersionStore() {
  const { templateVersionStore } = useRootStore()
  return templateVersionStore
}

export function useAdHocPdfStore() {
  const { adHocPdfStore } = useRootStore()
  return adHocPdfStore
}

export function useTransactionStore() {
  const { transactionStore } = useRootStore()
  return transactionStore
}
export function useAuthStore() {
  const { authStore } = useRootStore()
  return authStore
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
  // only create root store once (store is a singleton)
  const root = store ?? new RootStore()

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>
}
