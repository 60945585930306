import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Stack,
  TextField,
  IconButton,
  Typography,
  styled,
  Skeleton,
  Table,
  Alert,
  Checkbox,
} from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../providers/RootStoreProvider'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Paper } from '../components/Paper'
import UserManager from '../components/User/UserManager'
import GenerateApiKeyModal from '../components/GenerateApiKeyModal'
import { ROLES } from '../constants/roles'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type ColorPalette = 'primary' | 'success' | 'error' | 'warning'

const Account = () => {
  const { t, i18n } = useTranslation()
  const { accountId } = useParams()
  const { authStore, accountStore, projectStore } = useRootStore()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [color, setColor] = useState<ColorPalette | ''>('')
  const [logo, setLogo] = useState('')
  const [demo, setDemo] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [generateAPIKeyModalOpen, setGenerateApiKeyModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId) {
      accountStore.loadCurrent(accountId).then(() => {
        if (accountStore.currentAccount) {
          setName(accountStore.currentAccount.name)
          setDescription(accountStore.currentAccount.description || '')
          setColor(accountStore.currentAccount.color as ColorPalette) // Casting to ColorPalette
          setDemo(accountStore.currentAccount.demo)
          if (accountStore.currentAccount.logo) {
            const imageTypeChar = accountStore.currentAccount.logo.charAt(0)
            const imageType =
              imageTypeChar === '/' ? 'jpg' : imageTypeChar === 'i' ? 'png' : imageTypeChar === 'R' ? 'gif' : ''
            setLogo(`data:image/${imageType};base64,${accountStore.currentAccount.logo}`)
          }
        }
      })

      projectStore.setCurrentAccountId(accountId)
      projectStore.clearCurrent()
    }
  }, [accountId, accountStore, projectStore])

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setLogo(reader.result as string)
      reader.onerror = (error) => console.error('Error converting file to Base64:', error)
    }
  }

  const handleClear = () => {
    setName('')
    setDescription('')
    setColor('success')
    setLogo('')
    setDemo(false)
    setApiKey('')
  }

  const generateAPIKey = async () => {
    await accountStore.generateApiKey().then((e) => {
      setApiKey(e)
      setGenerateApiKeyModalOpen(false)
    })
  }

  const handleSave = async () => {
    if (accountStore.currentAccount) {
      setSubmitted(true)

      accountStore.currentAccount.setName(name)
      accountStore.currentAccount.setDescription(description)
      accountStore.currentAccount.setColor(color)
      accountStore.currentAccount.setLogo(logo)
      accountStore.currentAccount.setDemo(demo)

      await accountStore.updateCurrent().then(() => {
        navigate(`../${accountId}/home`)
        setSubmitted(false)
        handleClear()
      })
    }
  }

  const handleCancel = () => {
    navigate(`../${accountId}/home`)

    handleClear()
  }

  return (
    <Stack direction='column' height='inherit' alignItems='left'>
      <h1>{accountStore.isLoading ? <Skeleton width={300} /> : accountStore.currentAccount?.name}</h1>
      <Stack direction={'row'} spacing={5} justifyContent={'center'}>
        <Paper>
          <Stack flexDirection='row' justifyContent='space-between' className='PaperHeader'>
            <h2>{t('account.title')}</h2>
          </Stack>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px', 'td:last-child': { textAlign: 'end' } }}>
            <tbody>
              <tr>
                <td>
                  <Typography>{t('account.name')}</Typography>
                </td>
                <td>
                  {!accountStore.isLoading ? (
                    <TextField
                      label={t('account.name')}
                      value={name}
                      disabled={submitted || accountStore.isLoading}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    <Skeleton height={100} sx={{ margin: '-22px 0' }} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>{t('account.description')}</Typography>
                </td>
                <td>
                  {!accountStore.isLoading ? (
                    <TextField
                      label={t('account.description')}
                      multiline
                      value={description}
                      disabled={submitted}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    <Skeleton height={100} sx={{ margin: '-22px 0' }} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Color palette</Typography>
                </td>
                <td>
                  <Stack direction='row' spacing={1} justifyContent='end'>
                    {(['primary', 'success', 'error', 'warning'] as ColorPalette[]).map((palette) => (
                      <Button
                        aria-label='palette'
                        key={palette}
                        variant='contained'
                        color={palette}
                        className={color === palette ? 'selected' : ''}
                        disabled={submitted || accountStore.isLoading}
                        onClick={() => setColor(palette)}
                        sx={{
                          minWidth: 40,
                          minHeight: 40,
                          borderRadius: '20px !important',
                          '&.selected': {
                            border: 'solid #ffffff4f 4px',
                          },
                        }}
                      />
                    ))}
                  </Stack>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Logo</Typography>
                </td>
                <td>
                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'end' }}>
                    <VisuallyHiddenInput
                      type='file'
                      accept='image/*'
                      onChange={handleLogoChange}
                      id='icon-button-file'
                      disabled={submitted || accountStore.isLoading}
                    />
                    <label htmlFor='icon-button-file'>
                      <IconButton color='primary' aria-label='upload picture' component='span'>
                        <Avatar sx={{ width: 56, height: 56 }} src={logo} />
                      </IconButton>
                    </label>
                    {logo && (
                      <IconButton
                        onClick={() => setLogo('')}
                        style={{
                          position: 'absolute',
                          top: 15,
                          right: 15,
                          transform: 'translate(50%, -50%)',
                          background: '#f7f7f7',
                          borderRadius: '50%',
                          padding: '2px',
                        }}
                        size='small'
                        color='primary'
                      >
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )}
                  </div>
                </td>
              </tr>
              {authStore.hasRole(ROLES.ADMIN) && (
                <tr>
                  <td>
                    <Typography>{t('account.demo')}</Typography>
                  </td>
                  <td>
                    <Checkbox checked={demo} onChange={() => setDemo(!demo)} aria-label={t('account.demo')} />
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <Typography>{t('account.apiKey')}</Typography>
                </td>
                <td>
                  {apiKey === '' ? (
                    <Button
                      aria-label={t('account.apiKey')}
                      color='primary'
                      variant='contained'
                      disabled={submitted || accountStore.isLoading}
                      onClick={() => setGenerateApiKeyModalOpen(true)}
                    >
                      {t('account.generate')}
                    </Button>
                  ) : (
                    <TextField
                      label={t('account.apiKey')}
                      value={apiKey}
                      disabled={submitted}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton aria-label='toggle password visibility' onClick={generateAPIKey} edge='end'>
                            <RefreshIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                </td>
              </tr>
              {apiKey !== '' && (
                <tr>
                  <td colSpan={2}>
                    <Alert
                      sx={{
                        textAlign: 'center',
                        background: 'rgb(255, 244, 229)!important',
                        color: ' rgb(102, 60, 0)!important',
                        '.MuiAlert-icon ': { alignItems: 'center' },
                        '.MuiAlert-icon SVG': { color: '#ed6c02!important' },
                        '.MuiAlert-message': { margin: '0 auto' },
                      }}
                      severity='warning'
                    >
                      {t('account.apiKeyMessage')} <br />
                      {t('account.apiKeyMessage2')}
                    </Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Stack direction='row' spacing={1} justifyContent='end' mt='auto'>
            <Button
              color='secondary'
              variant='contained'
              disabled={submitted || accountStore.isLoading}
              onClick={handleSave}
            >
              {t('account.save')}
            </Button>
            <Button variant='outlined' disabled={submitted || accountStore.isLoading} onClick={handleCancel}>
              {t('account.cancel')}
            </Button>
          </Stack>
        </Paper>
        <UserManager />
      </Stack>
      <GenerateApiKeyModal
        generateApiKeyModalOpen={generateAPIKeyModalOpen}
        setGenerateApiKeyModalOpen={setGenerateApiKeyModalOpen}
        generateApiKey={generateAPIKey}
      />
    </Stack>
  )
}

export default observer(Account)

