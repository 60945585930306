import { makeAutoObservable } from 'mobx'

export class TemplateVersion {
  id = ''
  versionNumber = 0
  createdAt = ''
  lastModifiedAt = ''
  createdBy = ''
  lastModifiedBy = ''
  state = ''
  deployed = false
  domTemplate = { preprocess: { textSplitByAnnotations: true }, documentInfo: {}, spots: [], documents: [] }

  constructor(template: TemplateVersion | null = null) {
    template && Object.assign(this, template)
    makeAutoObservable(this)
  }
}
export default TemplateVersion
