import { makeAutoObservable } from 'mobx'

export class Template {
  id = ''
  name = ''
  description = ''

  constructor(template: Template | null = null) {
    template && Object.assign(this, template)
    makeAutoObservable(this)
  }

  setName(name: string) {
    this.name = name
  }

  setDescription(description: string) {
    this.description = description
  }
}
export default Template
