import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Stack, Table, TableBody } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import ArticleIcon from '@mui/icons-material/Article'
import { observer } from 'mobx-react'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'
import ProjectPropertiesModal from './ProjectPropertiesModal'
import { ROLES } from '../../constants/roles'

const ProjectManager = ({ editable, recentOnly }: { editable?: boolean; recentOnly?: boolean }) => {
  const { t } = useTranslation()

  const { accountId } = useParams()
  const navigate = useNavigate()
  const { authStore, accountStore, projectStore } = useRootStore()
  const [addProjectModalOpen, setAddProjectModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId) {
      accountStore.loadCurrent(accountId)
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadProjects()
      projectStore.clearCurrent()
    }
  }, [accountId])

  function handleImport(e: ChangeEvent<HTMLInputElement>) {
    const fileReader = new FileReader()
    e.target.files && fileReader.readAsText(e.target.files[0], 'JSON')

    fileReader.onloadend = () => {
      try {
        projectStore.import(JSON.parse(fileReader.result as string))
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      {editable && <h1> {t('projects.projectManager.projectManager')}</h1>}

      <>
        <Paper
          sx={{
            m: recentOnly ? '0px !important' : '0 auto',
          }}
          skeleton={projectStore.isLoading}
        >
          <Stack flexDirection={'row'} justifyContent={'space-between'} className='PaperHeader'>
            <h2> {recentOnly ? t('projects.projectManager.recent') : t('projects.projectManager.projects')}</h2>

            {editable && (
              <Stack direction={'row'} spacing={1}>
                {authStore.hasRole(ROLES.ADMIN) && (
                  <Button variant='contained' color='info' component='label' disabled={projectStore.isLoading}>
                    {t('projects.projectManager.import')}
                    <input type='file' accept='application/JSON' onChange={handleImport} hidden />
                  </Button>
                )}
                <Button
                  variant='contained'
                  color='success'
                  onClick={() => setAddProjectModalOpen(true)}
                  disabled={projectStore.isLoading}
                >
                  + {t('projects.projectManager.addProject')}
                </Button>
              </Stack>
            )}
            {recentOnly && (
              <Button
                variant='contained'
                color='success'
                onClick={() => navigate('/accounts/' + accountId + '/projects/')}
                disabled={projectStore.isLoading}
              >
                + {t('projects.projectManager.viewAll')}
              </Button>
            )}
          </Stack>
          <Table sx={{ m: 0, overflow: 'auto' }}>
            <TableBody>
              {!projectStore.isLoading ? (
                projectStore.projects.slice(0, recentOnly ? 3 : 100).map((p, i) => (
                  <RowIElement
                    key={'project-' + i}
                    onClick={() => {
                      navigate('/accounts/' + accountId + '/projects/' + p.id)
                    }}
                    title={p.name}
                    underTitle={p.description}
                    icon={<ArticleIcon />}
                  />
                ))
              ) : (
                <RowIElement icon={''} title={''} isLoading />
              )}
            </TableBody>
          </Table>
        </Paper>
        <ProjectPropertiesModal
          addProjectModalOpen={addProjectModalOpen}
          setAddProjectModalOpen={setAddProjectModalOpen}
        />
      </>
    </>
  )
}

export default observer(ProjectManager)

