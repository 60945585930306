import React, { ReactNode, useEffect, useRef, useState, useMemo } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone'
import FolderCopyTwoToneIcon from '@mui/icons-material/FolderCopyTwoTone'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import CollectionsIcon from '@mui/icons-material/Collections'
import BorderInnerTwoToneIcon from '@mui/icons-material/BorderInnerTwoTone'
import BorderAllTwoToneIcon from '@mui/icons-material/BorderAllTwoTone'
import TableChartIcon from '@mui/icons-material/TableChart'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import { SimpleTreeView, TreeItem, useTreeViewApiRef } from '@mui/x-tree-view'

import ContextMenu, { ContextMenuItem } from '../ContextMenu'

import '../../styles/DOMTemplateTreeView.css'

import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import domTemplate, {
  Section,
  DOMTemplate,
  Document,
  TextField,
  ImageField,
  Table,
  TableColumn,
  Spot,
  DocumentInfo,
  List,
} from '../../domain/DataModelsMobx'
import { getParent, hasParent } from 'mobx-state-tree'
import { Box } from '@mui/material'
import { handleDelete, getAllIds } from './DesignerFunctions'
import { useTranslation } from 'react-i18next'

import { Reorder } from 'framer-motion'
import SnippetModal from './SnippetModal'
import { useRootStore } from '../../providers/RootStoreProvider'

type AllTypes = Spot | Document | Section | TextField | ImageField | Table | TableColumn | List

function DOMTemplateTreeView({
  setSelectedData,
  selectedUID,
  multiSelectedUID,
  handleKeyDown,
}: {
  setSelectedData: (event: any, data: AllTypes | null) => void
  selectedUID: DocumentInfo | AllTypes | null
  multiSelectedUID: string[]
  handleKeyDown: (event: any) => void
}) {
  const { t } = useTranslation()
  const treeViewRef = useRef<HTMLDivElement | null>(null)
  const apiRef = useTreeViewApiRef()
  const { templateVersionStore } = useRootStore()

  const [snippetFunction, setSnippetFunction] = useState<{ function: (data: any) => void } | null>(null)

  const [expandedItems, setExpandedItems] = useState([...getAllIds(domTemplate), 'documents'])

  useEffect(() => {
    treeViewRef.current && multiSelectedUID.length > 0
      ? scrollToTreeItem(multiSelectedUID[1])
      : selectedUID && scrollToTreeItem(selectedUID.id)
  }, [selectedUID, multiSelectedUID])

  useEffect(() => {
    const idsToExpand: string[] = []

    if (selectedUID) {
      let parent = selectedUID
      while (hasParent(parent, 2)) {
        parent = getParent(parent, 2)
        parent.id && idsToExpand.unshift(parent.id)
      }
      idsToExpand.unshift('documents')
      setExpandedItems([...expandedItems, ...idsToExpand])
      setTimeout(() => {
        scrollToTreeItem(selectedUID.id)
      }, idsToExpand.length * 150)
    }
  }, [selectedUID])

  const scrollToTreeItem = (id: string) => {
    const selectedNode = document.getElementById('TreeView-' + id)
    selectedNode &&
      treeViewRef.current &&
      treeViewRef.current.scrollTo({ top: selectedNode.offsetTop - 50, behavior: 'smooth' })
  }

  const CustomTreeItem = observer(
    ({ item, spots, documents }: { item: AllTypes | DOMTemplate; spots?: boolean; documents?: boolean }) => {
      const onSelect = (e: React.KeyboardEvent<HTMLLIElement> | React.MouseEvent<HTMLLIElement>, item: AllTypes) => {
        e.preventDefault()
        if (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLLIElement>).key === 'Enter') {
          setSelectedData(e, item)
        } else if (e.type === 'click') {
          setSelectedData(e, item)
        }
      }

      const children = documents
        ? (item as DOMTemplate).documents
        : spots
          ? (item as DOMTemplate).spots
          : item.type === 'Document'
            ? (item as Document).sections
            : item.type === 'Section'
              ? (item as Section).children
              : item.type === 'Table'
                ? (item as Table).columns
                : null
      if (!children) return null

      function setChildren(e: any) {
        documents
          ? (item as DOMTemplate).setDocuments(e)
          : spots
            ? (item as DOMTemplate).setSpots(e)
            : item.type === 'Document'
              ? (item as Document).setSections(e)
              : item.type === 'Section'
                ? (item as Section).setChildren(e)
                : item.type === 'Table'
                  ? (item as Table).setColumns(e)
                  : console.log('Type not recognized')
      }

      return (
        <Reorder.Group values={children} onReorder={setChildren} as={'div'}>
          {children.map((item: AllTypes) => (
            <Reorder.Item key={item.id} value={item} as={'div'}>
              <TreeItem
                itemId={item.id}
                key={item.id}
                label={<Label item={item} />}
                onKeyDown={(e) => onSelect(e, item)}
                onClick={(e) => onSelect(e, item)}
              >
                {['Document', 'Section', 'Table'].includes(item.type) && <CustomTreeItem item={item} />}
              </TreeItem>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      )
    }
  )

  const Icon = ({ item, type }: { item: AllTypes | DOMTemplate; type?: string }) => {
    if (type) {
      item = item as DOMTemplate
      switch (type) {
        case 'Spots':
          return <BorderAllTwoToneIcon />
        case 'Documents':
          return <FolderCopyTwoToneIcon />
        default:
          return <FolderCopyTwoToneIcon />
      }
    } else {
      item = item as AllTypes
      switch (item.type) {
        case 'TextSpot':
          return <BorderInnerTwoToneIcon />
        case 'ImageSpot':
          return <CollectionsIcon />
        case 'Document':
          return <InsertDriveFileTwoToneIcon />
        case 'Section':
          return <HighlightAltIcon />
        case 'Table':
          return <TableChartIcon />
        case 'TableColumn':
          return <ViewColumnIcon />
        case 'TextField':
          return <div className='TreeLabelIcon'>{(item as TextField).structureProperties.structureType}</div>
        case 'ImageField':
          return <InsertPhotoIcon />
        case 'List':
          return <div className='TreeLabelIcon'>{(item as List).structureProperties.structureType}</div>
        default:
          return <FolderCopyTwoToneIcon />
      }
    }
  }

  const CustomContextMenu = ({
    item,
    type,
    children,
  }: {
    item: AllTypes | DOMTemplate
    type?: string
    children: ReactNode
  }) => {
    const handleNewSelect = (node: AllTypes | null) => {
      node && setSelectedData(null, node)
    }

    const handleDeleteInternal = (item: any) => {
      handleDelete(item as any, (n: any) => setSelectedData(null, n))
    }

    if (templateVersionStore.currentTemplateVersion?.state === 'APPROVED') {
      return <>{children}</>
    }

    if (type) {
      item = item as DOMTemplate
      switch (type) {
        case 'Spots':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleNewSelect(domTemplate.addSpot('TextSpot'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.TextSpot')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleNewSelect(domTemplate.addSpot('ImageSpot'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.ImageSpot')}
              </ContextMenuItem>
            </ContextMenu>
          )
        case 'Documents':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleNewSelect(domTemplate.addDocument())}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.Document')}
              </ContextMenuItem>
            </ContextMenu>
          )
        default:
          return <>{children}</>
      }
    } else {
      item = item as AllTypes
      switch (item.type) {
        case 'TextSpot':
        case 'ImageSpot':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleDeleteInternal(item)}>
                {t('designer.templateTreeView.delete')} {t('designer.dataTypes.' + item.type)}
              </ContextMenuItem>
            </ContextMenu>
          )
        case 'Document':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Document).addSection())}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.Section')}
              </ContextMenuItem>
              <ContextMenuItem
                onSelect={() => {
                  setSnippetFunction({
                    function: (data: any) => {
                      handleNewSelect((item as Document).addSnippet(data))
                    },
                  })
                }}
              >
                {t('designer.templateTreeView.addNew')} {t('designer.dataTypes.Snippet')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleDeleteInternal(item)}>
                {t('designer.templateTreeView.delete')} {t('designer.dataTypes.Document')}
              </ContextMenuItem>
            </ContextMenu>
          )
        case 'Section':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Section).addChild('Section'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.Section')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Section).addChild('TextField'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.TextField')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Section).addChild('ImageField'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.ImageField')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Section).addChild('Table'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.Table')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Section).addChild('List'))}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.List')}
              </ContextMenuItem>
              <ContextMenuItem
                onSelect={() => {
                  setSnippetFunction({
                    function: (data: any) => {
                      handleNewSelect((item as Section).addSnippet(data))
                    },
                  })
                }}
              >
                {t('designer.templateTreeView.addNew')} {t('designer.dataTypes.Snippet')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleDeleteInternal(item)}>
                {t('designer.templateTreeView.delete')} {t('designer.dataTypes.Section')}
              </ContextMenuItem>
            </ContextMenu>
          )
        case 'Table':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleNewSelect((item as Table).addColumn())}>
                {t('designer.templateTreeView.createNew')} {t('designer.dataTypes.TableColumn')}
              </ContextMenuItem>
              <ContextMenuItem onSelect={() => handleDeleteInternal(item)}>
                {t('designer.templateTreeView.delete')} {t('designer.dataTypes.Table')}
              </ContextMenuItem>
            </ContextMenu>
          )
        case 'TableColumn':
        case 'TextField':
        case 'ImageField':
        case 'List':
          return (
            <ContextMenu content={children}>
              <ContextMenuItem onSelect={() => handleDeleteInternal(item)}>
                {t('designer.templateTreeView.delete')}{' '}
                {t('designer.dataTypes.' + (item as TableColumn | TextField | ImageField | List).type)}
              </ContextMenuItem>
            </ContextMenu>
          )
        default:
          return <>{children}</>
      }
    }
  }

  const Label = ({ item, type }: { item: AllTypes | DOMTemplate; type?: string }) => {
    return (
      <CustomContextMenu item={item} type={type ? type : undefined}>
        <div
          title={type ? t('designer.dataTypes.' + type) : t('designer.dataTypes.' + (item as AllTypes).type)}
          className='TreeLabel DOMTreeLabel'
        >
          <Icon item={item} type={type ? type : undefined} />
          {type ? t('designer.dataTypes.' + type) : (item as AllTypes).name}
        </div>
      </CustomContextMenu>
    )
  }

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto !important',
        overflow: 'hidden',
      }}
      ref={treeViewRef}
      onKeyDown={handleKeyDown}
    >
      <SimpleTreeView
        apiRef={apiRef}
        aria-label='customized'
        slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
        multiSelect={multiSelectedUID.length !== 0}
        selectedItems={multiSelectedUID.length !== 0 ? multiSelectedUID.slice(1) : selectedUID ? selectedUID.id : ''}
        id='TreeView'
        expandedItems={expandedItems} // Use controlled state
        onExpandedItemsChange={(_, nodes) => setExpandedItems(nodes)} // Handle expansion changes
        expansionTrigger='iconContainer'
      >
        <TreeItem itemId='spots' label={<Label item={domTemplate} type='Spots' />}>
          {domTemplate.spots && <CustomTreeItem item={domTemplate} spots />}
        </TreeItem>
        <TreeItem itemId='documents' label={<Label item={domTemplate} type='Documents' />}>
          {domTemplate.documents && <CustomTreeItem item={domTemplate} documents />}
        </TreeItem>
      </SimpleTreeView>
      <SnippetModal snippetFunction={snippetFunction} setSnippetFunction={setSnippetFunction} />
    </Box>
  )
}

export default observer(DOMTemplateTreeView)
