import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { RootStore } from './RootStore'
import TemplateVersionService from '../api/TemplateVersionService'
import TemplateVersion from '../domain/TemplateVersion'
import eventEmitter from '../components/eventEmitter'
import SampleDocument from '../domain/SampleDocument'
import domTemplate, { DOMTemplate } from '../domain/DataModelsMobx'

export class TemplateVersionStore {
  root: RootStore
  templateversionService: TemplateVersionService
  currentAccountId = ''
  currentProjectId = ''
  currentTemplateId = ''
  currentTemplateVersion: TemplateVersion | null = null
  templateVersions: TemplateVersion[] = []
  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    this.templateversionService = new TemplateVersionService()
    makeAutoObservable(this)
  }

  setCurrentAccountId(accountId: string) {
    this.currentAccountId = accountId
  }

  setCurrentProjectId(projectId: string) {
    this.currentProjectId = projectId
  }

  setCurrentTemplateId(templateId: string) {
    this.currentTemplateId = templateId
  }

  async loadTemplateVersions() {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const templateVersions = await this.templateversionService.list(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId
      )
      runInAction(() => {
        this.templateVersions = templateVersions.map((t) => new TemplateVersion(t))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load templateVersions - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadCurrent(templateVersionId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const templateVersion = await this.templateversionService.get(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        templateVersionId
      )
      runInAction(() => {
        this.currentTemplateVersion = new TemplateVersion(templateVersion)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load current - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearCurrent() {
    this.currentTemplateVersion = null
  }

  async updateTemplateVersion(template: DOMTemplate) {
    if (!this.currentTemplateVersion) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - No template version selected ',
        severity: 'error',
      })

      return
    }
    try {
      await this.templateversionService.put(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        this.currentTemplateVersion.id,
        template
      )
      eventEmitter.emit('showSnackbar', {
        message: 'Template saved',
        severity: 'success',
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - ' + error,
        severity: 'error',
      })
    }
  }

  async delete(templateVersion: TemplateVersion) {
    if (this.templateVersions.length < 2) {
      eventEmitter.emit('showSnackbar', {
        message: 'Cannot delete the only version',
        severity: 'error',
      })
      return
    }
    try {
      console.log(
        await this.templateversionService.delete(
          this.currentAccountId,
          this.currentProjectId,
          this.currentTemplateId,
          templateVersion.id
        )
      )
      runInAction(() => {
        this.templateVersions = this.templateVersions.filter((t) => t.id !== templateVersion.id)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not delete template version - ' + error,
        severity: 'error',
      })
    }
  }

  async createFromVersion(templateVersion: TemplateVersion): Promise<TemplateVersion> {
    try {
      const newTemplateVersion: TemplateVersion = await this.templateversionService.createFromVersion(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        templateVersion.versionNumber
      )
      runInAction(() => {
        this.templateVersions.push(newTemplateVersion)
      })
      return newTemplateVersion
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create template version- ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  async deployTemplateVersion(templateVersion: TemplateVersion): Promise<TemplateVersion> {
    try {
      const newTemplateVersion: TemplateVersion = await this.templateversionService.deployTemplateVersion(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        templateVersion.id
      )

      runInAction(() => {
        const previousDeploeyed = this.templateVersions.findIndex((t) => t.deployed === true)
        if (previousDeploeyed != -1) {
          this.templateVersions[previousDeploeyed].deployed = false
        }
      })

      runInAction(() => {
        const versionIndex = this.templateVersions.findIndex((t) => t.id === newTemplateVersion?.id)
        if (versionIndex !== -1) {
          this.templateVersions[versionIndex] = newTemplateVersion
        }
      })
      return newTemplateVersion
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create template version- ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  setCurrentTemplateVersion(templateVersion: TemplateVersion) {
    runInAction(() => {
      this.currentTemplateVersion = templateVersion
    })
  }

  async updateVersionState(templateVersion: TemplateVersion, state: string, approveMessage: string) {
    try {
      const updatedVersion = await this.templateversionService.updateVersionState(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        templateVersion.id,
        state,
        approveMessage
      )

      const versionIndex = this.templateVersions.findIndex((t) => t.id === updatedVersion?.id)
      runInAction(() => {
        if (versionIndex != -1) {
          this.templateVersions[versionIndex] = updatedVersion
        }
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - ' + error,
        severity: 'error',
      })
    }
  }
  async produceDomResult(sampleDocumentId: string, template: DOMTemplate): Promise<any> {
    if (!this.currentTemplateVersion) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - No template version selected ',
        severity: 'error',
      })
      return
    }
    try {
      const result: any = await this.templateversionService.produceDomResult(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        this.currentTemplateVersion.id,
        sampleDocumentId,
        template
      )
      return result
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not produce results - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  async createPdf(sampleDocument: SampleDocument) {
    if (!this.currentTemplateVersion) {
      eventEmitter.emit('showSnackbar', {
        message: 'No sample document selected',
        severity: 'error',
      })
      return
    }
    this.templateversionService
      .createPdf(
        this.currentAccountId,
        this.currentProjectId,
        this.currentTemplateId,
        this.currentTemplateVersion,
        sampleDocument.id
      )
      .then((result) => this.downloadURI(result, sampleDocument.name ?? 'sampleDocument.pdf'))
      .catch((error) => {
        eventEmitter.emit('showSnackbar', {
          message: 'Could not create pdf - ' + error,
          severity: 'error',
        })
      })
  }

  downloadURI(blob: Blob, fileName: string) {
    const link = document.createElement('a')
    link.download = fileName
    link.href = URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
