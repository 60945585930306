import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Stack, Table, TableBody } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'
import UserPropertiesModal from './UserPropertiesModal'
import { stringAvatar } from '../StringAvatar'
import UserDeleteModal from './UserDeleteModal'

const UserManager = () => {
  const { t } = useTranslation()

  const { accountId, userId } = useParams()
  const navigate = useNavigate()
  const { accountStore, userStore } = useRootStore()
  const [editUserModalOpen, setEditUserModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [selectedUser, setSeletedUser] = useState<string | null>(null)

  useEffect(() => {
    if (userId) {
      // userStore.loadCurrent(userId)
    }

    // userStore.loadUsers()
    // userStore.clearCurrent()
    // userStore.loadLoggedIn('user-1')

    accountStore.currentAccount && accountStore.loadUsers()
  }, [accountId, userId, accountStore.currentAccount])

  return (
    <Paper
      sx={{
        m: '0 auto',
      }}
      skeleton={accountStore.isLoading}
    >
      <Stack flexDirection={'row'} justifyContent={'space-between'} className='PaperHeader'>
        <h2> {t('users.userManager.users')}</h2>
        <Button
          variant='contained'
          color='success'
          disabled={accountStore.isLoading}
          onClick={() => setEditUserModalOpen(true)}
        >
          + {t('users.userManager.giveAccess')}
        </Button>
      </Stack>

      <Table sx={{ m: 0, overflow: 'auto' }}>
        <TableBody>
          {!accountStore.isLoading ? (
            accountStore.users.map((u, i) => {
              const imageTypeChar = u && u.profilePicture && u.profilePicture.charAt(0)
              const imageType =
                imageTypeChar && imageTypeChar === '/'
                  ? 'jpg'
                  : imageTypeChar === 'i'
                    ? 'png'
                    : imageTypeChar === 'R'
                      ? 'gif'
                      : ''

              return (
                <RowIElement
                  key={'user-' + i}
                  title={u.firstName + ' ' + u.lastName}
                  onRemove={(e) => {
                    e.stopPropagation()
                    setSeletedUser(u.id)
                    setDeleteModalOpen(true)
                  }}
                  icon={
                    <Box m={0.2}>
                      <Avatar
                        alt={u.firstName + ' ' + u.lastName}
                        {...stringAvatar(u.firstName + ' ' + u.lastName)}
                        src={`data:image/${imageType};base64,${u.profilePicture}`}
                      ></Avatar>
                    </Box>
                  }
                />
              )
            })
          ) : (
            <RowIElement icon={''} title={''} isLoading />
          )}
        </TableBody>
      </Table>
      <UserPropertiesModal giveAccessModalOpen={editUserModalOpen} setGiveAccessModalOpen={setEditUserModalOpen} />
      <UserDeleteModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        selectedUser={selectedUser}
      />
    </Paper>
  )
}

export default observer(UserManager)
