import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Skeleton, Stack, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import AdHodPdfEngine from '../components/AdHodPdfEngine'
import SampleDocumentManager from '../components/SampleDocument/SampleDocumentManager'
import TemplatePropertiesModal from '../components/Template/TemplatePropertiesModal'
import TemplateDeleteModal from '../components/Template/TemplateDeleteModal'
import { useTranslation } from 'react-i18next'
import TemplateVersionManager from '../components/Template/TemplateVersionManager'

const Template = () => {
  const { t } = useTranslation()
  const { accountId, projectId, templateId } = useParams()
  const { accountStore, projectStore, templateStore, templateVersionStore, sampleDocumentStore } = useRootStore()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [editTemplateModalOpen, setEditTemplateModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (accountId && projectId && templateId) {
      accountStore.loadCurrent(accountId)
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadCurrent(projectId)
      templateStore.setCurrentAccountId(accountId)
      templateStore.setCurrentProjectId(projectId)
      templateStore.loadCurrent(templateId)
      sampleDocumentStore.clearCurrent()
    }
  }, [accountId, projectId, templateId])

  return (
    <>
      <Stack
        direction={'row'}
        sx={{ h1: { m: 0, lineHeight: 'Normal' }, h2: { m: 0, fontSize: 16, fontWeight: 400 }, m: ' 1.67em 0' }}
      >
        <h1>
          <Stack direction={'row'}>
            {templateStore.currentTemplate ? templateStore.currentTemplate.name + ' ' : <Skeleton width={300} />}
            {templateStore.currentTemplate && templateVersionStore.currentTemplateVersion ? (
              <Box sx={{ fontSize: 18, fontWeight: 400, alignItems: 'end', display: 'flex', ml: '8px', mb: '4px' }}>
                {' v' + templateVersionStore.currentTemplateVersion.versionNumber}
              </Box>
            ) : (
              <Skeleton width={50} sx={{ ml: '10px !important' }} />
            )}
          </Stack>
        </h1>
        <Stack
          direction={'row'}
          sx={{
            ml: 'auto',
          }}
        >
          <Tooltip title={t('templates.templateProperties.editTemplate')}>
            <span>
              <IconButton
                onClick={(e) => {
                  setEditTemplateModalOpen(true)
                  e.stopPropagation()
                }}
                disabled={!templateStore.currentTemplate}
              >
                <MoreVertIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('templates.templateProperties.deleteTemplate')}>
            <span>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleteModalOpen(true)
                }}
                disabled={!templateStore.currentTemplate}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Button
            sx={{ textTransform: 'none', ml: 1 }}
            variant='contained'
            color='success'
            disabled={!templateVersionStore.currentTemplateVersion || sampleDocumentStore.sampleDocuments.length == 0}
            onClick={() =>
              templateVersionStore.currentTemplateVersion &&
              sampleDocumentStore.sampleDocuments.length > 0 &&
              navigate(
                '/accounts/' +
                  accountId +
                  '/projects/' +
                  projectId +
                  '/templates/' +
                  templateId +
                  '/versions/' +
                  templateVersionStore.currentTemplateVersion.id +
                  '/sampleDocuments/' +
                  sampleDocumentStore.sampleDocuments[sampleDocumentStore.sampleDocuments.length - 1].id +
                  '/designer'
              )
            }
          >
            {t('templates.templateManager.enterDesigner')}
          </Button>
        </Stack>
      </Stack>
      <Stack direction={'column'} spacing={3} justifyContent={'center'} alignItems={'center'}>
        <TemplateVersionManager />
        <Stack direction={'row'} spacing={3} width={'90%'} maxHeight={'70%'} justifyContent={'center'}>
          <SampleDocumentManager
            accountId={accountId}
            projectId={projectId}
            templateId={templateStore.currentTemplate?.id}
            templateVersionId={templateVersionStore.currentTemplateVersion?.id}
          />
          <AdHodPdfEngine />
        </Stack>
      </Stack>
      <TemplateDeleteModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} />
      <TemplatePropertiesModal
        addTemplateModalOpen={editTemplateModalOpen}
        setAddTemplateModalOpen={setEditTemplateModalOpen}
      />
    </>
  )
}

export default observer(Template)
