import React from 'react'
import { Divider, IconButton, Link, Stack, Tooltip } from '@mui/material'

import Avatar from '@mui/material/Avatar'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom'

import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { stringAvatar } from './StringAvatar'
import { LOGOUT_URL } from '../constants/urls'

const UserMenu = () => {
  const { t } = useTranslation()

  const { accountStore, authStore } = useRootStore()

  const imageTypeChar =
    accountStore.currentAccount && accountStore.currentAccount.logo && accountStore.currentAccount.logo.charAt(0)
  const imageType =
    imageTypeChar && imageTypeChar === '/' ? 'jpg' : imageTypeChar === 'i' ? 'png' : imageTypeChar === 'R' ? 'gif' : ''

  const navigate = useNavigate()

  return (
    <Stack
      role='navigation'
      direction={'row'}
      alignItems={'center'}
      justifyContent={'end'}
      sx={{
        '& .MuiAvatar-root': { mr: 0 },
      }}
    >
      {authStore.loggedInUser && (
        <>
          <Tooltip title={t('menu.logout')}>
            <IconButton sx={{ svg: { fontSize: 20 } }} onClick={() => (window.location.href = LOGOUT_URL)}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>

          <Divider component='hr' orientation='vertical' sx={{ m: 1, height: '70%' }} />
          <Tooltip title={t('menu.userSettings')}>
            <Link component={RouterLink as any} to={'/user/'} underline='none'>
              <Avatar
                alt={authStore.loggedInUser.firstName + ' ' + authStore.loggedInUser.lastName}
                {...stringAvatar(authStore.loggedInUser.firstName + ' ' + authStore.loggedInUser.lastName)}
                src={`data:image/${imageType};base64,${authStore.loggedInUser.profilePicture}`}
              />
            </Link>
          </Tooltip>
        </>
      )}
    </Stack>
  )
}

export default observer(UserMenu)
