import React, { useEffect } from 'react'
import { Stack, Table, TableBody } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'
import PersonIcon from '@mui/icons-material/Person'
import Account from '../../domain/Account'

const AccountManager = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { accountStore } = useRootStore()

  useEffect(() => {
    accountStore.loadAccounts()
  }, [])

  return (
    <Paper>
      <Stack flexDirection={'row'} justifyContent={'space-between'} className='PaperHeader'>
        <h2>{t('account.accountManager.accounts')}</h2>
      </Stack>
      <Table sx={{ m: 0, overflow: 'auto' }}>
        <TableBody>
          {!accountStore.isLoading ? (
            accountStore.accounts.map((account: Account, i: number) => (
              <RowIElement
                key={'account-' + i}
                title={account.name}
                underTitle={account.description}
                icon={<PersonIcon />}
                onClick={() => {
                  navigate('/accounts/' + account.id + '/home')
                }}
              />
            ))
          ) : (
            <RowIElement icon={''} title={''} isLoading />
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default observer(AccountManager)
