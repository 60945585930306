import React from 'react'
import MainLayout from './pages/MainLayout'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import ProjectManager from './components/Project/ProjectManager'
import Designer from './pages/Designer'
import Page404 from './pages/Page404'
import Home from './pages/Home'
import FullScreenLayout from './pages/FullScreenLayout'
import AdminAccounts from './pages/Admin/AdminAccounts'
import Template from './pages/Template'

import Project from './pages/Project'
import TemplateManager from './components/Template/TemplateManager'
import Account from './pages/Account'
import User from './pages/User'
import TransactionTable from './components/TransactionTable'
import ProtectedRoute from './components/ProtectedRoute'
import Page403 from './pages/Page403'
import { ROLES } from './constants/roles'
import Accounts from './pages/Accounts'
import { useAuthStore } from './providers/RootStoreProvider'
import TemplateVersionManager from './components/Template/TemplateVersionManager'

// TODO lazy loading removed for now, it caused pagination compoment to render vertically

const RootRedirectRoute: React.FC = () => {
  const authStore = useAuthStore()
  if (authStore.loggedInUser) {
    if (authStore.loggedInUser.accounts.length == 1) {
      return <Navigate to={'/accounts/' + authStore.loggedInUser?.accounts[0] + '/home'} />
    } else {
      return <Navigate to='/accounts' />
    }
  }
  return <></>
}

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
            <RootRedirectRoute />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.ADMIN]}>
            <MainLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <AdminAccounts />,
          },
        ],
      },
      {
        path: 'transactions/',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.ADMIN]}>
            <MainLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <TransactionTable admin />,
          },
        ],
      },
      {
        path: 'user/',
        element: (
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <User />,
          },
        ],
      },
      {
        path: 'accounts/',
        element: <MainLayout />,

        children: [
          {
            index: true,
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <Accounts />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/home',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <Home />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/account',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.ACCOUNT_MANAGER]}>
                <Account />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/transactions/',
            element: <TransactionTable />,
          },
          {
            path: ':accountId/projects/',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <ProjectManager editable />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/projects/:projectId/',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <Project />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/projects/:projectId/templates',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.PROJECT_MANAGER]}>
                <TemplateManager editable />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/projects/:projectId/templates/:templateId',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <Template />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/projects/:projectId/templates/:templateId/versions',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <TemplateVersionManager />
              </ProtectedRoute>
            ),
          },
          {
            path: ':accountId/projects/:projectId/templates/:templateId/versions/:templateVersionId',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
                <Template />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/accounts/:accountId/projects/:projectId/templates/:templateId/versions/:templateVersionId/sampleDocuments/:sampleDocumentId/designer',
    element: (
      <ProtectedRoute requiredRoles={[ROLES.TEMPLATE_DESIGNER]}>
        <FullScreenLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Designer />,
      },
    ],
  },
  {
    path: '/forbidden',
    element: <Page403 />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
])

export default router
