import { makeAutoObservable } from 'mobx'

export class Transaction {
  id = ''
  accountId = ''
  projectId = ''
  templateId = ''
  accountName = ''
  projectName = ''
  templateName = ''
  creationTime: Date = new Date()
  sourceIP = ''
  engineVersion = ''
  templateVersion = ''
  processingTime = 0
  numberOfPages = 0
  processedBytes = 0
  statusCode: StatusCode = StatusCode.WARNING
  statusMessage = ''
  clientType: ClientType = ClientType.DESIGN
  demo = false

  constructor(transaction: Partial<Transaction> | null = null) {
    if (transaction) {
      Object.assign(this, transaction)
    }
    makeAutoObservable(this)
  }
}

export class TransactionResponse {
  content: Transaction[] = []
  page: TransactionPagination = new TransactionPagination()

  constructor(transactionResponse: Partial<TransactionResponse> | null = null) {
    if (transactionResponse) {
      Object.assign(this, transactionResponse)
    }
    makeAutoObservable(this)
  }
}

export class TransactionPagination {
  size = 0
  number = 0
  totalElements = 0
  totalPages = 0

  constructor(transactionPagination: Partial<TransactionPagination> | null = null) {
    if (transactionPagination) {
      Object.assign(this, transactionPagination)
    }
    makeAutoObservable(this)
  }
}

export enum StatusCode {
  OK = 'OK',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  // Add other status codes as needed
}

export enum ClientType {
  API = 'API',
  DESIGN = 'DESIGN',
}

export default Transaction

