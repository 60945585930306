import React, { useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Button, Container, MenuItem, Modal, Select, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { applySnapshot } from 'mobx-state-tree'
import { useParams } from 'react-router-dom'
import domTemplate from '../../domain/DataModelsMobx'
import { toJS } from 'mobx'

const AutoSaveModal = () => {
  const { t } = useTranslation()
  const { templateVersionId } = useParams()

  const [autoSaveFunction, setAutoSaveFunction] = useState<(() => void) | null>(null)

  useEffect(() => {
    const storedValue = localStorage.getItem('xper-docengine-domTemplate-' + templateVersionId)
    if (storedValue) {
      const autoSave = JSON.parse(storedValue)
      setAutoSaveFunction(() => () => applySnapshot(domTemplate, autoSave))
    }
  }, [templateVersionId])

  const handleClose = useCallback(() => {
    localStorage.removeItem('xper-docengine-domTemplate-' + templateVersionId)
    setAutoSaveFunction(null)
  }, [setAutoSaveFunction])

  return (
    <Modal open={autoSaveFunction != null}>
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='h1' sx={{ all: 'unset', fontSize: 22, fontWeight: 600, m: 1 }}>
            {t('designer.autoSaveModal.title')}
          </Typography>
          <Typography variant='h2' sx={{ all: 'unset' }}>
            {t('designer.autoSaveModal.underTitle')}
          </Typography>
        </Stack>

        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button
            color='success'
            variant='contained'
            onClick={() => {
              autoSaveFunction && autoSaveFunction()
              setAutoSaveFunction(null)
            }}
          >
            {t('designer.autoSaveModal.open')}
          </Button>
          <Button variant='outlined' color='error' onClick={handleClose}>
            {t('designer.autoSaveModal.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(AutoSaveModal)
