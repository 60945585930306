import { toJS } from 'mobx'
import Project from '../domain/Project'
import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/accounts/'

class ProjectService {
  list = async (accountId: string): Promise<Project[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + accountId + '/projects', options)
    return await apiRequest(request)
  }
  get = async (accountId: string, projectId: string): Promise<Project> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId, options)
    return await apiRequest(request)
  }
  post = async (accountId: string, project: Project): Promise<Project> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toJS(project)),
    }
    const request = new Request(webApiUrl + accountId + '/projects', options)
    return await apiRequest(request)
  }
  put = async (accountId: string, project: Project): Promise<Project> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(project)),
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + project.id, options)
    return await apiRequest(request)
  }
  delete = async (accountId: string, projectId: string): Promise<Response> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options = {
      method: 'DELETE',
      headers,
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId, options)
    return await apiRequest(request)
  }

  export = async (accountId: string, projectId: string): Promise<any> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId + '/export', options)
    return await apiRequest(request)
  }

  import = async (accountId: string, project: Project): Promise<Project> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toJS(project)),
    }
    const request = new Request(webApiUrl + accountId + '/projects/import', options)
    return await apiRequest(request)
  }
}

export default ProjectService

