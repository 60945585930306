import { Button, Container, Modal, Stack, Typography } from '@mui/material'

import { useBlocker, BlockerFunction } from 'react-router-dom'
import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const handleRouteChange = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  event.returnValue = ''
  return ''
}

type Props = {
  isBlocked: boolean
}

/**
 * Component provides a reusable way to handle confirmation of leaving a page
 * without saving changes and displays a modal for the user to confirm their decision.
 *
 * @param isBlocked - boolean flag to determine if navigation should be blocked
 *
 * @returns - Modal Component
 */
export const ConfirmNavigation = ({ isBlocked }: Props) => {
  // Allow the submission navigation to the same route to go through
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => isBlocked && currentLocation.pathname !== nextLocation.pathname,
    [isBlocked]
  )
  const { t } = useTranslation()

  const blocker = useBlocker(shouldBlock)
  const isBlockedState = blocker.state === 'blocked'
  const isProceedingState = blocker.state === 'proceeding'

  useEffect(() => {
    if (!isBlocked) {
      window.removeEventListener('beforeunload', handleRouteChange)
      blocker.reset?.()
    } else {
      window.addEventListener('beforeunload', handleRouteChange)
    }

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange)
    }
  }, [blocker, isBlocked])

  const handleSubmit = () => blocker.proceed?.()
  const handleCancel = () => blocker.reset?.()

  return (
    <Modal open={isBlockedState || isProceedingState} component='div'>
      <Container
        maxWidth='sm'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='h1' sx={{ all: 'unset', fontSize: 22, fontWeight: 600, m: 1 }}>
            {t('designer.confirmNavigation.title')}
          </Typography>
          <Typography variant='h2' sx={{ all: 'unset' }}>
            {t('designer.confirmNavigation.underTitle')}
          </Typography>
        </Stack>

        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button color='success' variant='contained' onClick={handleSubmit}>
            {t('designer.confirmNavigation.open')}
          </Button>
          <Button variant='outlined' color='error' onClick={handleCancel}>
            {t('designer.confirmNavigation.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

