import React, { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  ClickAwayListener,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import { Paper } from '../Paper'
import { useTranslation } from 'react-i18next'
import TemplateVersion from '../../domain/TemplateVersion'
import dayjs from 'dayjs'
import 'dayjs/locale/en' // Change the locale if necessary

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TemplateVersionModal from './TemplateVersionModal'
import eventEmitter from '../eventEmitter'

const VersionRow = observer(({ row }: { row: TemplateVersion }) => {
  const { t, i18n } = useTranslation()
  const { templateVersionStore, sampleDocumentStore } = useRootStore()
  const navigate = useNavigate()
  const { accountId, projectId, templateId, templateVersionId } = useParams()

  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [open, setOpen] = React.useState(false)
  const [templateVersionModalOpen, setTemplateVersionModalOpen] = useState<boolean>(false)
  const [modalversion, setModalversion] = useState<string>('false')
  const [modalAction, setModalAction] = useState<(() => void) | null>(null)

  const getColorForState = (state: string) => {
    switch (state) {
      case 'DEPLOYED':
        return 'warning'
      case 'APPROVED':
        return 'success'
      case 'WIP':
        return 'default'
      default:
        return 'default'
    }
  }

  return (
    <>
      <TableRow
        onClick={() => {
          sampleDocumentStore.sampleDocuments.length > 0
            ? navigate(
                '/accounts/' +
                  accountId +
                  '/projects/' +
                  projectId +
                  '/templates/' +
                  templateId +
                  '/versions/' +
                  row.id +
                  '/sampleDocuments/' +
                  sampleDocumentStore.sampleDocuments[sampleDocumentStore.sampleDocuments.length - 1].id +
                  '/designer'
              )
            : eventEmitter.emit('showSnackbar', {
                message: 'Upload sample document to enter designer',
                severity: 'error',
              })
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            templateVersionStore.setCurrentTemplateVersion(row)
          }
        }}
        aria-label={t('templateVersions.templateVersionManager.version') + ' ' + row.versionNumber}
        hover
        sx={{
          ...(row.id === templateVersionStore.currentTemplateVersion?.id && {
            background: 'rgba(0, 0, 0, 0.03)',
          }),
          ':hover': { cursor: 'pointer' },
          ':focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
          },
          td: { p: '4px 16px', fontSize: 12 },
        }}
      >
        <TableCell
          sx={
            row.id === templateVersionStore.currentTemplateVersion?.id
              ? {
                  fontSize: '18px !important',
                  fontWeight: 600,
                }
              : { fontSize: '16px !important', fontWeight: 400 }
          }
        >
          v{row.versionNumber}
        </TableCell>

        <TableCell>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {i18n.language === 'no'
              ? dayjs(row.createdAt).tz('Europe/Oslo').format('DD-MM-YYYY HH:mm:ss')
              : dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </LocalizationProvider>
        </TableCell>
        <TableCell>{row.createdBy}</TableCell>
        <TableCell>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {i18n.language === 'no'
              ? dayjs(row.lastModifiedAt).tz('Europe/Oslo').format('DD-MM-YYYY HH:mm:ss')
              : dayjs(row.lastModifiedAt).format('YYYY-MM-DD HH:mm:ss')}
          </LocalizationProvider>
        </TableCell>
        <TableCell>{row.lastModifiedBy}</TableCell>
        <TableCell>
          <Chip
            variant='filled'
            sx={{ fontSize: '12px !important' }}
            color={getColorForState(row.deployed ? 'DEPLOYED' : row.state)}
            label={row.deployed ? 'DEPLOYED' : row.state}
          ></Chip>
        </TableCell>
        <TableCell sx={{ width: '10px' }} padding={'none'}>
          <Tooltip title={t('templateVersions.actions.options')} placement='top'>
            <IconButton
              aria-label='expand row'
              size='small'
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              ref={anchorRef}
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.stopPropagation()
                  setOpen(!open)
                }
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <ClickAwayListener onClickAway={() => setOpen(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
            <Collapse in={open} timeout='auto' unmountOnExit sx={{ position: 'absolute', ml: 7 }}>
              <Box sx={{ position: 'relative' }}>
                <Stack
                  m={1}
                  p={1}
                  spacing={1}
                  sx={{
                    background: 'white',
                    position: 'absolute',
                    right: 0,
                    borderRadius: '6px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    zIndex: 1000,
                  }}
                >
                  <Tooltip title={t('templateVersions.actions.selectTip')} placement='top'>
                    <Button
                      variant='outlined'
                      color='info'
                      onClick={(e) => {
                        e.stopPropagation()
                        templateVersionStore.setCurrentTemplateVersion(row)
                        setOpen(false)
                      }}
                    >
                      {t('templateVersions.actions.select')}
                    </Button>
                  </Tooltip>
                  {row.state === 'APPROVED' && (
                    <Tooltip title={t('templateVersions.actions.copyTip')} placement='right'>
                      <Button
                        variant='outlined'
                        color='warning'
                        onClick={(e) => {
                          e.stopPropagation()
                          templateVersionStore.createFromVersion(row)
                          setOpen(false)
                        }}
                      >
                        {t('templateVersions.actions.copy')}
                      </Button>
                    </Tooltip>
                  )}
                  {row.state === 'WIP' && (
                    <>
                      <Tooltip title={t('templateVersions.actions.approveTip')} placement='right'>
                        <Button
                          variant='outlined'
                          onClick={(e) => {
                            e.stopPropagation()
                            setModalversion('approve')
                            setModalAction(() => async (message: string) => {
                              await templateVersionStore.updateVersionState(row, 'APPROVED', message)
                            })
                            setTemplateVersionModalOpen(true)
                            setOpen(false)
                          }}
                          color='success'
                        >
                          {t('templateVersions.actions.approve')}
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('templateVersions.actions.deleteTip')}>
                        <Button
                          variant='outlined'
                          onClick={(e) => {
                            e.stopPropagation()
                            setModalversion('delete')
                            setModalAction(() => () => templateVersionStore.delete(row))
                            setTemplateVersionModalOpen(true)
                            setOpen(false)
                          }}
                          color='error'
                        >
                          {t('templateVersions.actions.delete')}
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  {row.state === 'APPROVED' && !row.deployed && (
                    <Tooltip title={t('templateVersions.actions.deployTip')}>
                      <Button
                        variant='outlined'
                        color='success'
                        onClick={(e) => {
                          e.stopPropagation()
                          setModalversion('deploy')
                          setModalAction(() => () => templateVersionStore.deployTemplateVersion(row))
                          setTemplateVersionModalOpen(true)
                        }}
                      >
                        {t('templateVersions.actions.deploy')}
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
              </Box>
            </Collapse>
          </ClickAwayListener>
        </TableCell>
      </TableRow>
      <TemplateVersionModal
        modalOpen={templateVersionModalOpen}
        setmodalOpen={setTemplateVersionModalOpen}
        version={modalversion}
        action={modalAction}
        message={modalversion === 'approve'}
      />
    </>
  )
})

const TemplateVersionManager = () => {
  const { t } = useTranslation()
  const { accountId, projectId, templateId, templateVersionId } = useParams()
  const { templateVersionStore } = useRootStore()

  useEffect(() => {
    if (accountId && projectId && templateId) {
      templateVersionStore.setCurrentAccountId(accountId)
      templateVersionStore.setCurrentProjectId(projectId)
      templateVersionStore.setCurrentTemplateId(templateId)
      templateVersionStore.loadTemplateVersions().then(() => {
        if (templateVersionId) {
          templateVersionStore.loadCurrent(templateVersionId)
        } else if (
          templateVersionStore.currentTemplateVersion &&
          templateVersionStore.templateVersions.some(
            (version) => version.id === templateVersionStore.currentTemplateVersion?.id
          )
        ) {
          templateVersionStore.loadCurrent(templateVersionStore.currentTemplateVersion.id)
        } else if (templateVersionStore.templateVersions.length > 0) {
          const newestId = templateVersionStore.templateVersions[templateVersionStore.templateVersions.length - 1].id
          templateVersionStore.loadCurrent(newestId)
        }
      })
    }
  }, [accountId, projectId, templateId, templateVersionId])

  return (
    <>
      <Paper
        skeleton={templateVersionStore.isLoading}
        sx={{ width: '90%', maxWidth: '1224px !important', maxHeight: 400, minHeight: 200 }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          mb='4px'
          alignItems={'center'}
          className='PaperHeader'
        >
          <h2>{t('templateVersions.templateVersions')}</h2>
        </Stack>
        <TableContainer sx={{ height: '100%' }}>
          <Table aria-label='Versions'>
            <TableHead
              sx={{
                th: {
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <TableRow>
                <TableCell>{t('templateVersions.templateVersionManager.version')}</TableCell>
                <TableCell>{t('templateVersions.templateVersionManager.createdAt')}</TableCell>
                <TableCell>{t('templateVersions.templateVersionManager.createdBy')}</TableCell>
                <TableCell>{t('templateVersions.templateVersionManager.lastModifiedAt')}</TableCell>
                <TableCell>{t('templateVersions.templateVersionManager.lastModifiedBy')}</TableCell>
                <TableCell>{t('templateVersions.templateVersionManager.state')}</TableCell>
                <TableCell component={'td'}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!templateVersionStore.isLoading
                ? templateVersionStore.templateVersions
                    .slice()
                    .reverse()
                    .map((version, i) => <VersionRow key={version + '-' + i} row={version} />)
                : Array.from({ length: 2 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={7} sx={{ p: '4px' }}>
                        <Skeleton height={34} sx={{ transform: 'none' }} />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default observer(TemplateVersionManager)

