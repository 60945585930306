import React, { useEffect, useState } from 'react'
import { Button, IconButton, Stack, Table, TableBody, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import Account from '../../domain/Account'
import PersonIcon from '@mui/icons-material/Person'
import { Paper } from '../../components/Paper'
import { useTranslation } from 'react-i18next'
import RowIElement from '../../components/RowIElement'
import AccountPropertiesModal from '../../components/Account/AccountPropertiesModal'
import AccountDeleteModal from '../../components/Account/AccountDeleteModal'

const AdminAccounts = () => {
  const { t } = useTranslation()
  const { accountStore } = useRootStore()
  const navigate = useNavigate()

  const [addAccountModalOpen, setAddAccountModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  useEffect(() => {
    accountStore.loadAccounts()
    accountStore.clearCurrent()
  }, [])

  return (
    <Stack direction={'column'} spacing={3} height={'inherit'} sx={{ alignItems: 'center' }}>
      <h1>{t('adminAccount.welcome')}</h1>

      <Paper>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} className='PaperHeader'>
          <h2>{t('account.accountManager.accounts')}</h2>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              accountStore.clearCurrent()
              setAddAccountModalOpen(true)
            }}
          >
            + {t('account.accountManager.addAccount')}
          </Button>
        </Stack>
        <Table sx={{ m: 0, overflow: 'auto' }}>
          <TableBody>
            {!accountStore.isLoading ? (
              accountStore.accounts.map((account: Account) => (
                <RowIElement
                  key={account.id}
                  title={account.name}
                  underTitle={account.description}
                  icon={<PersonIcon />}
                  onClick={() => {
                    navigate('/accounts/' + account.id + '/home')
                  }}
                  disabled={accountStore.isLoading}
                  secondButton={
                    <Tooltip title={t('account.accountManager.adminAccount')}>
                      <span>
                        <IconButton
                          size='small'
                          disabled={accountStore.isLoading}
                          onClick={(e) => {
                            accountStore.loadCurrent(account.id).then(() => {
                              setAddAccountModalOpen(true)
                            })
                            e.stopPropagation()
                          }}
                          sx={{
                            ml: 'auto!important',
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  }
                  onRemove={(e) => {
                    accountStore.loadCurrent(account.id)
                    e.stopPropagation()
                    setDeleteModalOpen(true)
                  }}
                />
              ))
            ) : (
              <RowIElement icon={''} title={''} isLoading />
            )}
          </TableBody>
        </Table>
        <AccountPropertiesModal
          addAccountModalOpen={addAccountModalOpen}
          setAddAccountModalOpen={setAddAccountModalOpen}
        />
        <AccountDeleteModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} />
      </Paper>
    </Stack>
  )
}

export default observer(AdminAccounts)
