import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend) // Add the backend plugin first
  .use(LanguageDetector) // Then the language detector
  .use(initReactI18next) // Finally, pass the instance to react-i18next
  .init({
    fallbackLng: 'en',
    debug: false,
    supportedLngs: ['en', 'no', 'nl'],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

export default i18n

