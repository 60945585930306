import * as React from 'react'
import router from './router'
import { RouterProvider } from 'react-router-dom'
import i18n from './i18n'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'

export default function App() {
  document.documentElement.lang = i18n.language

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}
