import { IAnyModelType, Instance, destroy, detach, getSnapshot, types } from 'mobx-state-tree'
import { v4 as uuidv4 } from 'uuid'
import { UndoManager } from 'mst-middlewares'

const StartEndIdentification = types
  .model('StartEndIdentification', {
    enabled: false,
    spots: types.array(types.string),
  })
  .actions((self) => ({
    setEnabled(enabled: boolean) {
      self.enabled = enabled
    },
    setSpots(spots: string[]) {
      self.spots.replace(spots)
    },
  }))

const SpotOffset = types
  .model('SpotOffset', {
    spot: types.string,
    id: types.optional(types.identifier, () => uuidv4()),
    offsetTop: types.number,
    offsetLeft: types.number,
    offsetRight: types.number,
    offsetBottom: types.number,
  })
  .actions((self) => ({
    setSpot(spot: string) {
      self.spot = spot
    },
    setOffsetTop(offsetTop: number) {
      self.offsetTop = offsetTop
    },
    setOffsetLeft(offsetLeft: number) {
      self.offsetLeft = offsetLeft
    },
    setOffsetRight(offsetRight: number) {
      self.offsetRight = offsetRight
    },
    setOffsetBottom(offsetBottom: number) {
      self.offsetBottom = offsetBottom
    },
  }))

const StructureProperties = types
  .model('StructureProperties', {
    structureType: '',
    language: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    alternateDescription: types.maybeNull(types.string),
    actualText: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setStructureType(structureType: string) {
      self.structureType = structureType
    },
    setLanguage(language: string | null) {
      self.language = language
    },
    setTitle(title: string | null) {
      self.title = title
    },
    setAlternateDescription(alternateDescription: string | null) {
      self.alternateDescription = alternateDescription
    },
    setActualText(actualText: string | null) {
      self.actualText = actualText
    },
  }))

const Positioning = types
  .model('Positioning', {
    top: false,
    left: false,
    bottom: false,
    right: false,
    spotOffsets: types.array(SpotOffset),
  })
  .actions((self) => ({
    setTop(top: boolean) {
      self.top = top
    },
    setLeft(left: boolean) {
      self.left = left
    },
    setBottom(bottom: boolean) {
      self.bottom = bottom
    },
    setRight(right: boolean) {
      self.right = right
    },
    addSpotOffset(spotId: string) {
      self.spotOffsets.push(
        SpotOffset.create({ spot: spotId, offsetTop: 0, offsetLeft: 0, offsetRight: 0, offsetBottom: 0 })
      )
    },
    deleteSpotOffset(index: number) {
      destroy(self.spotOffsets[index])
    },
    setSpotOffsets(spotOffsets: any[]) {
      self.spotOffsets.replace(spotOffsets)
    },
  }))

const Bbox = types
  .model('Bbox', {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  .actions((self) => ({
    setX(x: number) {
      self.x = x
    },
    setY(y: number) {
      self.y = y
    },
    setWidth(width: number) {
      self.width = width
    },
    setHeight(height: number) {
      self.height = height
    },
    preSet() {
      self.x = 100
      self.y = 100
      self.width = 100
      self.height = 100
    },
    setAll(values: { x: number; y: number; width: number; height: number }) {
      self.x = values.x
      self.y = values.y
      self.width = values.width
      self.height = values.height
    },
  }))

const TableColumn = types
  .model('TableColumn', {
    type: 'TableColumn',
    name: '',
    emptyCellText: types.maybeNull(types.string),
    alternateDescription: types.maybeNull(types.string),
    id: types.optional(types.identifier, () => uuidv4()),
    bbox: types.optional(
      types.late(() => Bbox),
      () => Bbox.create()
    ),
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setEmptyCellText(emptyCellText: string) {
      self.emptyCellText = emptyCellText
    },
    setAlternateDescription(alternateDescription: string) {
      self.alternateDescription = alternateDescription
    },
  }))

const TableCellOptions = types
  .model('TableCellOptions', {
    type: 'TableCellOptions',
    id: types.optional(types.identifier, () => uuidv4()),
    enabled: true,
    name: '',
    scope: 'COLUMN_HEADER',
    fromRow: 1,
    toRow: 1,
    fromColumn: 1,
    toColumn: 1,
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setScope(scope: string) {
      self.scope = scope
    },
    setEnabled(enabled: boolean) {
      self.enabled = enabled
    },
    setFromRow(fromRow: number) {
      self.fromRow = fromRow
    },
    setToRow(toRow: number) {
      self.toRow = toRow
    },
    setFromColumn(fromColumn: number) {
      self.fromColumn = fromColumn
    },
    setToColumn(toColumn: number) {
      self.toColumn = toColumn
    },
  }))

const SmartDetectProperties = types
  .model('SmartDetectProperties', {
    type: 'SmartDetectProperties',
    id: types.optional(types.identifier, () => uuidv4()),
    makeBoldLineHeader: true,
    boldHeaderStructureType: 'H1',
    joinLineBelowRatio: 100,
    lineTolerance: 2.0,
    sortAllElements: true,
  })
  .actions((self) => ({
    setMakeBoldLineHeader(makeBoldLineHeader: boolean) {
      self.makeBoldLineHeader = makeBoldLineHeader
    },
    setBoldHeaderStructureType(boldHeaderStructureType: string) {
      self.boldHeaderStructureType = boldHeaderStructureType
    },
    setJoinLineBelowRatio(joinLineBelowRatio: number) {
      self.joinLineBelowRatio = joinLineBelowRatio
    },
    setLineTolerance(lineTolerance: number) {
      self.lineTolerance = lineTolerance
    },
    setSortAllElements(sortAllElements: boolean) {
      self.sortAllElements = sortAllElements
    },
  }))

const RowBreaker = types
  .model('RowBreaker', {
    type: types.string,
    id: types.optional(types.identifier, () => uuidv4()),

    enabled: true,
    fromColumn: 1,
    toColumn: 1,
  })
  .actions((self) => ({
    setEnabled(enabled: boolean) {
      self.enabled = enabled
    },
    setFromColumn(fromColumn: number) {
      self.fromColumn = fromColumn
    },
    setToColumn(toColumn: number) {
      self.toColumn = toColumn
    },
  }))

// Extend the base model to create more specialized models
const EmptySpaceRowBreaker = RowBreaker.named('EmptySpaceRowBreaker')
  .props({
    type: types.literal('EmptySpaceRowBreaker'),
    spaceThreshold: 0,
    breakOnEveryLine: false,
    topAligned: false,
  })
  .actions((self) => ({
    setSpaceThreshold(spaceThreshold: number) {
      self.spaceThreshold = spaceThreshold
    },
    setBreakOnEveryLine(breakOnEveryLine: boolean) {
      self.breakOnEveryLine = breakOnEveryLine
    },
    setTopAligned(topAligned: boolean) {
      self.topAligned = topAligned
    },
  }))

const FixedHeightRowBreaker = RowBreaker.named('FixedHeightRowBreaker')
  .props({
    type: types.literal('FixedHeightRowBreaker'),
    rowHeight: 0,
  })
  .actions((self) => ({
    setRowHeight(rowHeight: number) {
      self.rowHeight = rowHeight
    },
  }))

const StringRowBreaker = RowBreaker.named('StringRowBreaker')
  .props({
    type: types.literal('StringRowBreaker'),
    rowBreakString: '',
    useRegex: false,
  })
  .actions((self) => ({
    setRowBreakString(rowBreakString: string) {
      self.rowBreakString = rowBreakString
    },
    setUseRegex(useRegex: boolean) {
      self.useRegex = useRegex
    },
  }))

const Field = types
  .model('Field', {
    type: types.string,
    id: types.optional(types.identifier, () => uuidv4()),
    name: '',
    enabled: true,
    structureProperties: types.optional(
      types.late(() => StructureProperties),
      () => StructureProperties.create(),
      [null, undefined]
    ),
    startIdentification: types.optional(
      types.late(() => StartEndIdentification),
      () => StartEndIdentification.create(),
      [null, undefined]
    ),
    endIdentification: types.optional(
      types.late(() => StartEndIdentification),
      () => StartEndIdentification.create(),
      [null, undefined]
    ),
    positioning: types.optional(
      types.late(() => Positioning),
      () => Positioning.create()
    ),
    bbox: types.optional(
      types.late(() => Bbox),
      () => Bbox.create()
    ),
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setEnabled(enabled: boolean) {
      self.enabled = enabled
    },
  }))

// Extend the base model to create more specialized models
const Table = Field.named('Table')
  .props({
    type: types.literal('Table'),
    columns: types.array(TableColumn),
    rowHeader: 0,
    rowBreakers: types.array(types.union(EmptySpaceRowBreaker, FixedHeightRowBreaker, StringRowBreaker)),
    tableCellOptions: types.array(TableCellOptions),
    createVirtualHeaderRow: false,
  })
  .actions((self) => ({
    addColumn() {
      const newColumn = TableColumn.create({
        name: 'Column ' + (self.columns.length + 1),
        bbox: self.columns.length
          ? {
              x: self.columns[self.columns.length - 1].bbox.width + self.columns[self.columns.length - 1].bbox.x,
              y: self.columns[self.columns.length - 1].bbox.y,
              width: self.columns[self.columns.length - 1].bbox.width,
              height: self.columns[self.columns.length - 1].bbox.height,
            }
          : { x: 50 * self.columns.length, y: 0, width: 50, height: self.bbox.height },
      })
      self.columns.push(newColumn)
      return newColumn
    },
    deleteColumn(index: number) {
      destroy(self.columns[index])
    },
    addRowBreaker(type: string) {
      if (type === 'EmptySpaceRowBreaker') {
        self.rowBreakers.push(EmptySpaceRowBreaker.create({ type: 'EmptySpaceRowBreaker' }))
      } else if (type === 'FixedHeightRowBreaker') {
        self.rowBreakers.push(FixedHeightRowBreaker.create({ type: 'FixedHeightRowBreaker' }))
      } else if (type === 'StringRowBreaker') {
        self.rowBreakers.push(StringRowBreaker.create({ type: 'StringRowBreaker' }))
      }
    },
    deleteRowBreaker(index: number) {
      destroy(self.rowBreakers[index])
    },

    addTableCellOptions() {
      const newTableCellOptions = TableCellOptions.create({ name: 'New TableCellOptions' })
      self.tableCellOptions.push(newTableCellOptions)
    },
    deleteTableCellOptions(index: number) {
      destroy(self.tableCellOptions[index])
    },

    setRowHeader(rowHeader: number) {
      self.rowHeader = rowHeader
    },
    setCreateVirtualHeaderRow(createVirtualHeaderRow: boolean) {
      self.createVirtualHeaderRow = createVirtualHeaderRow
    },
    clone(children: any[], name?: string): any[] {
      const clonedChildren: any[] = []
      for (const child of children) {
        const copiedChild = TableColumn.create({
          ...getSnapshot(child),
          id: uuidv4(),
          name: child.name + (name ? name : ''),
        })
        const index = self.columns.push(copiedChild) - 1
        clonedChildren.push(self.columns[index])
      }
      return clonedChildren
    },
    setColumns(newColumns: any[]) {
      self.columns.replace(newColumns)
    },
    setTableCellOptions(tableCellOptions: any[]) {
      self.tableCellOptions.replace(tableCellOptions)
    },
    setRowBreakers(rowBreakers: any[]) {
      self.rowBreakers.replace(rowBreakers)
    },
  }))

const ImageField = Field.named('ImageField').props({
  type: types.literal('ImageField'),
})

const TextField = Field.named('TextField').props({
  type: types.literal('TextField'),
})

const List = Field.named('List')
  .props({
    type: types.literal('List'),
    rowBreakers: types.array(types.union(EmptySpaceRowBreaker, FixedHeightRowBreaker, StringRowBreaker)),
  })
  .actions((self) => ({
    addRowBreaker(type: string) {
      if (type === 'EmptySpaceRowBreaker') {
        self.rowBreakers.push(EmptySpaceRowBreaker.create({ type: 'EmptySpaceRowBreaker' }))
      } else if (type === 'FixedHeightRowBreaker') {
        self.rowBreakers.push(FixedHeightRowBreaker.create({ type: 'FixedHeightRowBreaker' }))
      } else if (type === 'StringRowBreaker') {
        self.rowBreakers.push(StringRowBreaker.create({ type: 'StringRowBreaker' }))
      }
    },
    deleteRowBreaker(index: number) {
      destroy(self.rowBreakers[index])
    },
    setRowBreakers(rowBreakers: any[]) {
      self.rowBreakers.replace(rowBreakers)
    },
  }))

const Section = Field.named('Section')
  .props({
    type: types.literal('Section'),
    children: types.array(
      types.union(
        TextField,
        ImageField,
        Table,
        List,
        types.late((): IAnyModelType => Section)
      )
    ),
    enableSmartDetect: false,
    smartDetectProperties: types.optional(
      types.late(() => SmartDetectProperties),
      () => SmartDetectProperties.create()
    ),
  })
  .actions((self) => ({
    addChild(type: string) {
      let newChild
      switch (type) {
        case 'ImageField':
          newChild = ImageField.create({
            type: 'ImageField',
            name: 'New image field',
            structureProperties: { structureType: 'Figure' },
            bbox: { x: 100, y: 100, width: 100, height: 100 },
          })
          break
        case 'Table':
          newChild = Table.create({
            type: 'Table',
            name: 'New table field',
            structureProperties: { structureType: 'Table' },
            bbox: { x: 100, y: 100, width: 300, height: 100 },
          })
          break
        case 'List':
          newChild = List.create({
            type: 'List',
            name: 'New list field',
            structureProperties: { structureType: 'L' },
            bbox: { x: 100, y: 100, width: 300, height: 100 },
          })
          break
        case 'Section':
          newChild = Section.create({
            type: 'Section',
            name: 'New Section',
            structureProperties: { structureType: 'Sect' },
            bbox: { x: 100, y: 100, width: 300, height: 100 },
          })
          break
        default:
          // TextField is the default
          newChild = TextField.create({
            type: 'TextField',
            name: 'New text field',
            structureProperties: { structureType: 'P' },
            bbox: { x: 100, y: 100, width: 100, height: 100 },
          })
      }
      self.children.push(newChild)
      return newChild
    },
    addChildObject(child: any) {
      self.children.push(child)
    },
    deleteChild(index: number) {
      destroy(self.children[index])
    },
    clone(children: any[], name?: string): any[] {
      const clonedChildren: any[] = []
      for (const child of children) {
        const copiedChild: any = {
          ...getSnapshot(child),
          id: uuidv4(),
          name: child.name + (name ? name : ''),
        }
        if (child.type === 'Section') {
          copiedChild.children = []
          self.children.push(copiedChild)
          self.children[self.children.length - 1].clone(child.children)
        } else if (child.type === 'Table') {
          copiedChild.columns = []
          self.children.push(copiedChild)
          self.children[self.children.length - 1].clone(child.columns)
        } else {
          self.children.push(copiedChild)
        }
        clonedChildren.push(self.children[self.children.length - 1])
      }
      return clonedChildren
    },
    setChildren(newChildren: any[]) {
      self.children.replace(newChildren)
    },
    addSnippet(snippet: any) {
      const newChild = Section.create(snippet)
      self.children.push(newChild)
      return newChild
    },
    setEnableSmartDetect(enableSmartDetect: boolean) {
      self.enableSmartDetect = enableSmartDetect
    },
  }))

const Document = types
  .model('Document', {
    type: 'Document',
    id: types.optional(types.identifier, () => uuidv4()),
    name: '',
    language: types.maybeNull(types.string),
    startIdentification: types.optional(
      types.late(() => StartEndIdentification),
      () => StartEndIdentification.create(),
      [null, undefined]
    ),
    endIdentification: types.optional(
      types.late(() => StartEndIdentification),
      () => StartEndIdentification.create(),
      [null, undefined]
    ),
    sections: types.array(Section),
  })
  .actions((self) => ({
    addSection() {
      const newSection = Section.create({
        type: 'Section',
        name: 'New Section',
        structureProperties: { structureType: 'Sect' },
        bbox: { x: 100, y: 100, width: 100, height: 100 },
      })
      self.sections.push(newSection)
      return newSection
    },
    deleteSection(index: number) {
      destroy(self.sections[index])
    },
    setName(name: string) {
      self.name = name
    },
    setLanguage(language: string | null) {
      self.language = language
    },
    clone(children: any[], name?: string): any[] {
      const clonedChildren: any[] = []
      for (const child of children) {
        const copiedChild: any = {
          ...getSnapshot(child),
          id: uuidv4(),
          name: child.name + (name ? name : ''),
          children: [],
        }
        const index = self.sections.push(copiedChild) - 1
        self.sections[index].clone(child.children)
        clonedChildren.push(self.sections[index])
      }
      return clonedChildren
    },
    setSections(newSections: any[]) {
      self.sections.replace(newSections)
    },
    addSnippet(snippet: any) {
      const newChild = Section.create(snippet)
      self.sections.push(newChild)
      return newChild
    },
  }))

const Preprocess = types.model('Preprocess', {
  textSplitByAnnotations: true,
})

const Spot = types
  .model('Spot', {
    type: types.string,
    id: types.optional(types.identifier, () => uuidv4()),
    name: '',
    searchWholePage: false,
    searchArea: types.optional(
      types.late(() => Bbox),
      () => Bbox.create()
    ),
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setSearchWholePage(value: boolean) {
      self.searchWholePage = value
    },
  }))

const TextSpot = Spot.named('TextSpot')
  .props({
    type: types.literal('TextSpot'),
    searchType: '',
    searchString: '',
  })
  .actions((self) => ({
    setSearchType(type: string) {
      self.searchType = type
    },
    setSearchString(str: string) {
      self.searchString = str
    },
  }))

const ImageSpot = Spot.named('ImageSpot')
  .props({
    type: types.literal('ImageSpot'),
    imageWidth: types.number,
    imageHeight: types.number,
  })
  .actions((self) => ({
    setImageWidth(value: number) {
      self.imageWidth = value
    },
    setImageHeight(value: number) {
      self.imageHeight = value
    },
  }))

const DocumentInfo = types
  .model('DocumentInfo', {
    type: 'DocumentInfo',
    id: types.optional(types.identifier, () => uuidv4()),
    title: types.maybeNull(types.string),
    author: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
    keywords: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setTitle(title: string | null) {
      self.title = title === '' ? null : title
    },
    setAuthor(author: string | null) {
      self.author = author === '' ? null : author
    },
    setSubject(subject: string | null) {
      self.subject = subject === '' ? null : subject
    },
    setKewords(keywords: string | null) {
      self.keywords = keywords === '' ? null : keywords
    },
  }))

const DOMTemplate = types
  .model('DOMTemplate', {
    type: 'DOMTemplate',
    preprocess: types.optional(
      types.late(() => Preprocess),
      () => Preprocess.create()
    ),
    spots: types.array(types.union(TextSpot, ImageSpot)),
    documents: types.array(Document),
    documentInfo: types.optional(
      types.late(() => DocumentInfo),
      () => DocumentInfo.create()
    ),
  })
  .actions((self) => ({
    addSpot(type: string) {
      let newSpot
      switch (type) {
        case 'TextSpot':
          newSpot = TextSpot.create({
            type: 'TextSpot',
            name: 'New Spot',
            searchArea: { x: 100, y: 100, width: 100, height: 100 },
            searchType: 'contains',
          })
          break
        case 'ImageSpot':
          newSpot = ImageSpot.create({
            type: 'ImageSpot',
            name: 'New Image Spot',
            searchArea: { x: 100, y: 100, width: 100, height: 100 },
            imageHeight: 0,
            imageWidth: 0,
          })
          break
        default:
          // TextField is the default
          newSpot = TextSpot.create({
            type: 'TextSpot',
            name: 'New Text Spot',
            searchArea: { x: 100, y: 100, width: 100, height: 100 },
            searchType: 'contains',
          })
      }
      self.spots.push(newSpot)
      return newSpot
    },
    deleteSpot(index: number) {
      destroy(self.spots[index])
    },
    addDocument() {
      const newDocument = Document.create({ name: 'New Document' })
      self.documents.push(newDocument)
      return newDocument
    },
    deleteDocument(index: number) {
      destroy(self.documents[index])
    },
    clone(children: any[], name?: string): any[] {
      const clonedChildren: any[] = []

      if (children[0].type === 'Document') {
        for (const child of children) {
          const copiedChild: any = {
            ...getSnapshot(child),
            id: uuidv4(),
            name: child.name + (name ? name : ''),
            sections: [],
          }
          const index = self.documents.push(copiedChild) - 1
          self.documents[index].clone(child.sections)
          clonedChildren.push(self.documents[index])
        }
      } else {
        for (const child of children) {
          const copiedChild: any = {
            ...getSnapshot(child),
            id: uuidv4(),
            name: child.name + (name ? name : ''),
          }
          const index = self.spots.push(copiedChild) - 1
          self.spots[index].id = uuidv4()
          clonedChildren.push(self.spots[index])
        }
      }
      return clonedChildren
    },
    setDocuments(newDocuments: any[]) {
      self.documents.replace(newDocuments)
    },
    setSpots(newSpots: any[]) {
      self.spots.replace(newSpots)
    },
  }))

export type DOMTemplate = Instance<typeof DOMTemplate>
export type Document = Instance<typeof Document>
export type Spot = Instance<typeof Spot>
export type TextSpot = Instance<typeof TextSpot>
export type ImageSpot = Instance<typeof ImageSpot>
export type Section = Instance<typeof Section>
export type Table = Instance<typeof Table>
export type TableColumn = Instance<typeof TableColumn>
export type TableCellOptions = Instance<typeof TableCellOptions>
export type List = Instance<typeof List>
export type TextField = Instance<typeof TextField>
export type ImageField = Instance<typeof ImageField>
export type SmartDetectProperties = Instance<typeof SmartDetectProperties>
export type Bbox = Instance<typeof Bbox>
export type RowBreaker = Instance<typeof RowBreaker>
export type EmptySpaceRowBreaker = Instance<typeof EmptySpaceRowBreaker>
export type FixedHeightRowBreaker = Instance<typeof FixedHeightRowBreaker>
export type Positioning = Instance<typeof Positioning>
export type StartEndIdentification = Instance<typeof StartEndIdentification>
export type StringRowBreaker = Instance<typeof StringRowBreaker>
export type StructureProperties = Instance<typeof StructureProperties>
export type DocumentInfo = Instance<typeof DocumentInfo>
export type SpotOffset = Instance<typeof SpotOffset>

const domTemplate = DOMTemplate.create()

export const undoManager = UndoManager.create({}, { targetStore: domTemplate })

export default domTemplate
