import React from 'react'
import { Checkbox, IconButton, Skeleton, Stack, TableCell, TableRow, Tooltip } from '@mui/material'

import { observer } from 'mobx-react'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'

const RowElement = ({
  icon,
  title,
  underTitle,
  onClick,
  onCheck,
  secondButton,
  checked,
  onRemove,
  disabled,
  isLoading,
}: {
  icon: React.ReactNode
  title: string
  underTitle?: string
  onClick?: (e: any) => void
  secondButton?: React.ReactNode
  onCheck?: (e: any) => void
  checked?: boolean
  onRemove?: (e: any) => void
  disabled?: boolean
  isLoading?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <>
      {!isLoading ? (
        <TableRow
          hover
          sx={{
            ':hover': { cursor: 'pointer' },
            ':focus-visible': {
              outline: '-webkit-focus-ring-color auto 1px',
            },
            td: { padding: 0 },
            height: '42px',
          }}
          tabIndex={0}
          onClick={onClick && onClick}
          role='button'
          onKeyDown={(e) => {
            if (!disabled && onClick && (e.key === 'Enter' || e.key === ' ')) {
              e.preventDefault()
              onClick && onClick(e)
            }
          }}
        >
          {onCheck && (
            <TableCell width={50}>
              <Checkbox
                inputProps={{ 'aria-label': t('elementRow.checkOf') }}
                disabled={disabled}
                checked={checked}
                onClick={onCheck}
                sx={{
                  color: 'var(--secondary-color)',
                  '&.Mui-checked': {
                    color: 'var(--secondary-color)',
                  },
                }}
              />
            </TableCell>
          )}
          <TableCell
            sx={{
              svg: { fontSize: 24, color: 'var(--primary-color)', mb: '-6px' },
              width: 40,
            }}
          >
            {icon}
          </TableCell>
          <TableCell>{title}</TableCell>
          <TableCell
            sx={{
              fontSize: 12,
              maxWidth: 200, // percentage also works
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {underTitle && underTitle}
          </TableCell>

          {secondButton && (
            <TableCell align='right' width={30}>
              {secondButton}
            </TableCell>
          )}

          {onRemove && (
            <TableCell align='right' width={30}>
              <Tooltip title={t('elementRow.remove')}>
                <span>
                  <IconButton size='small' disabled={disabled} onClick={onRemove}>
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      ) : (
        Array.from({ length: 3 }).map((_, index) => (
          <TableRow key={index}>
            <TableCell width={'100%'} sx={{ padding: 1 }}>
              <Skeleton height={25} sx={{ transform: 'none' }} />
            </TableCell>
          </TableRow>
        ))
      )}
    </>
  )
}

export default observer(RowElement)
