import React, { useEffect, useState, useCallback } from 'react'
import { Button, Container, Modal, Stack, TextField, Typography } from '@mui/material'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react-lite'
import Project from '../../domain/Project'
import { useTranslation } from 'react-i18next'
import { ROLES } from '../../constants/roles'

const ProjectPropertiesModal = ({
  addProjectModalOpen,
  setAddProjectModalOpen,
}: {
  addProjectModalOpen: boolean
  setAddProjectModalOpen: (data: boolean) => void
}) => {
  const { t } = useTranslation()
  const { authStore, projectStore } = useRootStore()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (projectStore.currentProject) {
      setName(projectStore.currentProject.name || '')
      setDescription(projectStore.currentProject.description || '')
    } else {
      setName('')
      setDescription('')
    }
  }, [projectStore.currentProject])

  const resetForm = useCallback(() => {
    setName('')
    setDescription('')
  }, [])

  const handleSave = useCallback(() => {
    setSubmitted(true)
    if (projectStore.currentProject) {
      projectStore.currentProject.setName(name)
      projectStore.currentProject.setDescription(description)
      projectStore.updateCurrent().then(() => {
        setSubmitted(false)
        setAddProjectModalOpen(false)
      })
    } else {
      const newProject = new Project(null)
      newProject.setName(name)
      newProject.setDescription(description)
      projectStore.create(newProject).then(() => {
        resetForm()
        projectStore.loadProjects()
        setSubmitted(false)
        setAddProjectModalOpen(false)
      })
    }
  }, [name, description, projectStore, resetForm, setAddProjectModalOpen])

  const handleExport = () => {
    projectStore.currentProject && projectStore.export(projectStore.currentProject?.id)
  }

  return (
    <Modal
      open={addProjectModalOpen}
      onClose={() => {
        setAddProjectModalOpen(false)
      }}
    >
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Typography variant='h6' component='h2' mb={2}>
          {projectStore.currentProject
            ? t('projects.projectProperties.editProject')
            : t('projects.projectProperties.addProject')}
        </Typography>

        <Stack spacing={2}>
          <TextField
            label={t('projects.projectProperties.name')}
            value={name}
            disabled={submitted}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            label={t('projects.projectProperties.description')}
            value={description}
            disabled={submitted}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
          {authStore.hasRole(ROLES.ADMIN) && (
            <Button color='secondary' variant='contained' disabled={submitted} onClick={handleExport}>
              {t('projects.projectProperties.export')}
            </Button>
          )}
          <Stack direction='row' justifyContent='flex-end' spacing={2}>
            <Button color='secondary' variant='contained' disabled={submitted} onClick={handleSave}>
              {projectStore.currentProject ? t('projects.projectProperties.save') : t('projects.projectProperties.add')}
            </Button>
            <Button variant='outlined' disabled={submitted} onClick={() => setAddProjectModalOpen(false)}>
              {t('projects.projectProperties.cancel')}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(ProjectPropertiesModal)

