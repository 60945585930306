import { toJS } from 'mobx'
import TemplateVersion from '../domain/TemplateVersion'
import apiRequest from './ApiRequest'
import { DOMTemplate } from '../domain/DataModelsMobx'

const webApiUrl = '/bff/api/accounts/'

class TemplateVersionService {
  list = async (accountId: string, projectId: string, templateId: string): Promise<TemplateVersion[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(
      webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId + '/versions',
      options
    )
    return await apiRequest(request)
  }
  get = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersionId: string
  ): Promise<TemplateVersion> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(
      webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId + '/versions/' + templateVersionId,
      options
    )
    return await apiRequest(request)
  }
  createFromVersion = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersion: number
  ): Promise<TemplateVersion> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toJS(templateVersion)),
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/versions/fromVersion/' +
        templateVersion,
      options
    )
    return await apiRequest(request)
  }
  deployTemplateVersion = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersionId: string
  ): Promise<TemplateVersion> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/versions/' +
        templateVersionId +
        '/deploy',
      options
    )
    return await apiRequest(request)
  }

  delete = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersionId: string
  ): Promise<Response> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options = {
      method: 'DELETE',
      headers,
    }
    const request = new Request(
      webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId + '/versions/' + templateVersionId,
      options
    )
    return await apiRequest(request)
  }
  put = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersionId: string,
    domTemplate: DOMTemplate
  ): Promise<TemplateVersion> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(domTemplate)),
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/versions/' +
        templateVersionId +
        '/dom',
      options
    )
    return await apiRequest(request)
  }
  updateVersionState = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersionId: string,
    state: string,
    approveMessage: string
  ): Promise<any> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify({ state, approveMessage }),
    }
    const request = new Request(
      webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId + '/versions/' + templateVersionId,
      options
    )
    console.log('REQUESR', request)
    return await apiRequest(request)
  }

  produceDomResult = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersionId: string,
    sampleDocumentId: string,
    template: DOMTemplate
  ): Promise<any> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(toJS(template)),
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/versions/' +
        templateVersionId +
        '/sampleDocuments/' +
        sampleDocumentId +
        '/produceDomResult',
      options
    )
    return await apiRequest(request)
  }

  createPdf = async (
    accountId: string,
    projectId: string,
    templateId: string,
    templateVersion: TemplateVersion,
    sampleDocumentId: string
  ): Promise<any> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(toJS(templateVersion.domTemplate)),
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/versions/' +
        templateVersion.id +
        '/sampleDocuments/' +
        sampleDocumentId +
        '/createPdf',
      options
    )
    return await apiRequest(request, 'blob')
  }
}

export default TemplateVersionService

