import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Stack, Table, TableBody } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import ArticleIcon from '@mui/icons-material/Article'
import { observer } from 'mobx-react'
import TemplatePropertiesModal from './TemplatePropertiesModal'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'
import { ROLES } from '../../constants/roles'

const TemplateManager = ({ editable }: { editable?: boolean }) => {
  const { t } = useTranslation()

  const { accountId, projectId } = useParams()
  const navigate = useNavigate()
  const { authStore, templateStore } = useRootStore()
  const [addTemplateModalOpen, setAddTemplateModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId && projectId) {
      templateStore.setCurrentAccountId(accountId)
      templateStore.setCurrentProjectId(projectId)
      templateStore.loadTemplates()
      templateStore.clearCurrent()
    }
  }, [accountId])

  function handleImport(e: ChangeEvent<HTMLInputElement>) {
    const fileReader = new FileReader()
    e.target.files && fileReader.readAsText(e.target.files[0], 'JSON')

    fileReader.onloadend = () => {
      try {
        templateStore.import(JSON.parse(fileReader.result as string))
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      <Paper skeleton={templateStore.isLoading}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} className='PaperHeader'>
          <h2>{t('templates.templateManager.templates')}</h2>
          {editable && (
            <Stack direction={'row'} spacing={1}>
              {authStore.hasRole(ROLES.ADMIN) && (
                <Button variant='contained' color='info' component='label' disabled={templateStore.isLoading}>
                  {t('templates.templateManager.import')}
                  <input type='file' accept='application/JSON' onChange={handleImport} hidden />
                </Button>
              )}
              <Button
                variant='contained'
                color='success'
                onClick={() => setAddTemplateModalOpen(true)}
                disabled={templateStore.isLoading}
              >
                + {t('templates.templateManager.addTemplate')}
              </Button>
            </Stack>
          )}
        </Stack>
        <Table sx={{ m: 0, overflow: 'auto' }}>
          <TableBody>
            {!templateStore.isLoading ? (
              templateStore.templates.map((t, i) => (
                <RowIElement
                  key={'template-' + i}
                  onClick={() => {
                    navigate('/accounts/' + accountId + '/projects/' + projectId + '/templates/' + t.id)
                  }}
                  title={t.name}
                  underTitle={t.description}
                  icon={<ArticleIcon />}
                />
              ))
            ) : (
              <RowIElement icon={''} title={''} isLoading />
            )}
          </TableBody>
        </Table>
      </Paper>
      <TemplatePropertiesModal
        addTemplateModalOpen={addTemplateModalOpen}
        setAddTemplateModalOpen={setAddTemplateModalOpen}
      />
    </>
  )
}

export default observer(TemplateManager)

