import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import { ROLES } from '../constants/roles'

export const MainListItems = observer(() => {
  const { t } = useTranslation()

  const { authStore, accountStore } = useRootStore()

  const [selectedPage, setSelectedPage] = useState('')

  const { pathname } = useLocation()
  const { accountId } = useParams()

  useEffect(() => {
    pathname.includes('accounts/') && setSelectedPage('Accounts')
    pathname.includes('account') && accountId && setSelectedPage('Account')
    pathname.includes('home') && setSelectedPage('Home')
    pathname.includes('projects') && setSelectedPage('Projects')
    pathname.includes('admin') && setSelectedPage('Admin')
    pathname.includes('transactions') && setSelectedPage('TransactionsAdmin')
    pathname.includes('transactions') && setSelectedPage('Transactions')
  }, [pathname, accountId, accountStore])

  const handleListItemClick = (pageName: string) => {
    setSelectedPage(pageName)
  }

  return (
    <>
      {authStore.hasRole(ROLES.ADMIN) && (
        <>
          <ListItemButton
            component={Link}
            to={window.origin + '/admin'}
            onClick={() => handleListItemClick('Admin')}
            selected={selectedPage === 'Admin'}
          >
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('menu.admin')}
              className={selectedPage === 'Admin' ? 'MainListItemsSelected' : ''}
            />
          </ListItemButton>
        </>
      )}

      <ListItemButton
        component={Link}
        to={window.origin + '/accounts/'}
        onClick={() => handleListItemClick('Accounts')}
        selected={selectedPage === 'Accounts'}
      >
        <ListItemIcon>
          <PeopleAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('menu.accounts')}
          className={selectedPage === 'Accounts' ? 'MainListItemsSelected' : ''}
        />
      </ListItemButton>
      {accountStore.currentAccount && !pathname.includes('admin') && (
        <>
          {authStore.hasRole(ROLES.ACCOUNT_MANAGER) && (
            <ListItemButton
              component={Link}
              to={window.origin + '/accounts/' + accountStore.currentAccount.id + '/account'}
              onClick={() => handleListItemClick('Account')}
              selected={selectedPage === 'Account'}
            >
              <ListItemIcon>
                <PersonOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('menu.account')}
                className={selectedPage === 'Account' ? 'MainListItemsSelected' : ''}
              />
            </ListItemButton>
          )}

          <ListItemButton
            component={Link}
            to={window.origin + '/accounts/' + accountStore.currentAccount.id + '/home'}
            onClick={() => handleListItemClick('Home')}
            selected={selectedPage === 'Home'}
          >
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu.home')} className={selectedPage === 'Home' ? 'MainListItemsSelected' : ''} />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={window.origin + '/accounts/' + accountStore.currentAccount.id + '/projects'}
            onClick={() => handleListItemClick('Projects')}
            selected={selectedPage === 'Projects'}
          >
            <ListItemIcon>
              <LayersOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('menu.projects')}
              className={selectedPage === 'Projects' ? 'MainListItemsSelected' : ''}
            />
          </ListItemButton>
        </>
      )}
      {(authStore.hasRole(ROLES.ADMIN) || (accountStore.currentAccount && !pathname.includes('admin'))) && (
        <ListItemButton
          component={Link}
          to={
            authStore.hasRole(ROLES.ADMIN)
              ? window.origin + '/transactions'
              : accountStore.currentAccount
                ? window.origin + '/accounts/' + accountStore.currentAccount.id + '/transactions'
                : ''
          }
          onClick={() => handleListItemClick('Transactions')}
          selected={selectedPage === 'Transactions'}
        >
          <ListItemIcon>
            <SyncAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('menu.transactions')}
            className={selectedPage === 'Transactions' ? 'MainListItemsSelected' : ''}
          />
        </ListItemButton>
      )}
    </>
  )
})
