import React, { useEffect, useRef, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import '../styles/ResizableBox.css'
import 'react-pdf/dist/Page/TextLayer.css'
import DOMTemplateTreeView from '../components/Designer/DOMTemplateTreeView'
import DOMTreeView from '../components/Designer/DOMTreeView'
import PropertiesViewer from '../components/Designer/PropertiesView'
import CanvasView from '../components/Designer/CanvasView'
import domTemplate, {
  Document as DOMDocument,
  ImageField,
  Section,
  Spot,
  TableColumn,
  Table,
  TextField,
  DocumentInfo,
  List,
  undoManager,
  DOMTemplate,
} from '../domain/DataModelsMobx'
import { useParams } from 'react-router-dom'
import PanelBox from '../components/PanelBox'
import { ResizableBox } from 'react-resizable'
import UABoundBoxView from '../components/Designer/UABoundBoxView'
import { onSnapshot, applySnapshot } from 'mobx-state-tree'
import { debounce } from 'lodash'
import { useScrollContainer } from 'react-indiana-drag-scroll'
import { Box, Stack, Skeleton, Button } from '@mui/material'
import {
  handleCopy,
  handleDelete,
  handleDuplicate,
  handlePaste,
  handleSelectData,
  handleUndo,
} from '../components/Designer/DesignerFunctions'
import { useRootStore } from '../providers/RootStoreProvider'
import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'
import PDFParserTreeView from '../components/Designer/PDFParserTreeView'
import ToolbarComponent from '../components/Designer/ToolbarComponent'
import AutoSaveModal from '../components/Designer/AutoSaveModal'
import { ConfirmNavigation } from '../components/Designer/ConfirmNavigation'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
}

const Designer = () => {
  const { t } = useTranslation()

  const { accountId, projectId, templateId, templateVersionId, sampleDocumentId } = useParams()
  const { accountStore, projectStore, templateStore, templateVersionStore, sampleDocumentStore } = useRootStore()

  const [pdf, setPdf] = useState<string>()
  const [pageNum, setPageNum] = useState<number>(1)
  const [pageScale, setPageScale] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  const [debugTree, setDebugTree] = useState<any>(-1)
  const [domRoot, setDomRoot] = useState<any[]>([-1])

  const [selectedData, setSelectedData] = useState<
    DocumentInfo | DOMDocument | Spot | Section | TextField | ImageField | Table | TableColumn | List | null
  >(null)
  const [multiSelectedData, setMultiSelectedData] = useState<
    (DOMDocument | Spot | Section | TextField | ImageField | Table | TableColumn | List)[]
  >([])
  const [selectedProducedData, setSelectedProducedData] = useState<any>(null)

  const [clipBoard, setClipBoard] = useState<
    (DOMDocument | Spot | Section | TextField | ImageField | Table | TableColumn | List)[]
  >([])
  const [showParserResult, setShowParserResult] = useState<boolean>(false)
  const [isBlocking, setIsBlocking] = React.useState(false)

  const scrollContainer = useScrollContainer({
    mouseScroll: { ignoreElements: '.PDFHeader, .ResizableBoxContainer, .react-draggable' },
  })
  const canvasRef = useRef<any>(null)

  useEffect(() => {
    if (accountId && projectId && templateId && templateVersionId && sampleDocumentId) {
      accountStore.loadCurrent(accountId)
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadCurrent(projectId)
      templateStore.setCurrentAccountId(accountId)
      templateStore.setCurrentProjectId(projectId)
      templateStore.loadCurrent(templateId)

      templateVersionStore.setCurrentAccountId(accountId)
      templateVersionStore.setCurrentProjectId(projectId)
      templateVersionStore.setCurrentTemplateId(templateId)
      templateVersionStore.loadTemplateVersions()
      templateVersionStore.loadCurrent(templateVersionId).then(() => {
        templateVersionStore.currentTemplateVersion &&
          applySnapshot(domTemplate, toJS(templateVersionStore.currentTemplateVersion.domTemplate))
        localStorage.setItem('xper-docengine-domTemplate-initiated', 'true')

        undoManager.clear()
      })

      sampleDocumentStore.setCurrentAccountId(accountId)
      sampleDocumentStore.setCurrentProjectId(projectId)
      sampleDocumentStore.setCurrentTemplateId(templateId)
      sampleDocumentStore.loadSampleDocuments()
      sampleDocumentStore.loadCurrent(sampleDocumentId).then(() => {
        sampleDocumentStore.currentSampleDocument &&
          sampleDocumentStore.currentSampleDocument.content &&
          setPdf('data:application/pdf;base64,' + sampleDocumentStore.currentSampleDocument.content)
        setScrollPosition()
      })
      setSelectedProducedData(null)
      setSelectedData(null)
    }
  }, [accountId, projectId, templateId, templateVersionId])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      return
    }
    event.preventDefault()
    if (event.ctrlKey && event.shiftKey && event.keyCode === 90) {
      if (undoManager.canRedo) undoManager.redo()
    } else if (event.ctrlKey && event.keyCode === 90) {
      handleUndo()
    } else if (event.keyCode === 8) {
      handleDelete(selectedData, setSelectedData, multiSelectedData, setMultiSelectedData)
    } else if (event.ctrlKey && event.keyCode === 68) {
      handleDuplicate(selectedData, setSelectedData, multiSelectedData, setMultiSelectedData)
    } else if (event.ctrlKey && event.keyCode === 67) {
      handleCopy(selectedData, multiSelectedData, setClipBoard)
    } else if (event.ctrlKey && event.keyCode === 86) {
      handlePaste(selectedData, setSelectedData, multiSelectedData, setMultiSelectedData, clipBoard)
    } else if (event.ctrlKey && event.keyCode === 187) {
      handlePageScale(1)
    } else if (event.ctrlKey && event.keyCode === 189) {
      handlePageScale(-1)
    }
  }

  useEffect(() => {
    if (accountId && projectId && templateId && sampleDocumentId) {
      sampleDocumentStore.loadCurrent(sampleDocumentId).then(() => {
        sampleDocumentStore.currentSampleDocument &&
          sampleDocumentStore.currentSampleDocument.content &&
          setPdf('data:application/pdf;base64,' + sampleDocumentStore.currentSampleDocument.content)
        setScrollPosition()
      })
      setSelectedProducedData(null)
      produceResult(domTemplate)
    }
  }, [sampleDocumentId])

  useEffect(() => {
    const onSnapshotHandler = debounce((newSnapshot: any) => {
      produceResult(newSnapshot)
      if (localStorage.getItem('xper-docengine-domTemplate-initiated')) {
        localStorage.removeItem('xper-docengine-domTemplate-initiated')
      } else {
        localStorage.setItem('xper-docengine-domTemplate-' + templateVersionId, JSON.stringify(toJS(domTemplate)))
        setIsBlocking(true)
      }
    }, 500) // Adjust the wait time as needed (e.g., 500ms)

    const unsubscribe = onSnapshot(domTemplate, onSnapshotHandler)

    return () => {
      unsubscribe()
      onSnapshotHandler.cancel() // Cancel any pending API calls on component unmount
    }
  }, [sampleDocumentId])

  function produceResult(domTemplate: DOMTemplate, newSampleDocumentId?: string) {
    if (
      accountId &&
      projectId &&
      templateId &&
      templateVersionStore.currentTemplateVersion &&
      sampleDocumentId &&
      domTemplate != undefined &&
      domTemplate != null
    ) {
      templateVersionStore
        .produceDomResult(newSampleDocumentId ? newSampleDocumentId : sampleDocumentId, domTemplate)
        .then((result) => {
          result && setDomRoot(result.domRoots)
          result && setDebugTree(result.debugTree)
        })
    }
  }

  function setScrollPosition() {
    const containerNode = canvasRef.current
    if (containerNode && containerNode.scrollTo) {
      containerNode.scrollLeft = (containerNode.scrollWidth - containerNode.clientWidth) / 2
      containerNode.scrollTop = (containerNode.scrollHeight - containerNode.clientHeight) / 2
    }
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setTotalPages(numPages)
    setScrollPosition()
  }

  function handlePageScale(num: number) {
    num > 0 ? setPageScale(pageScale * 1.2) : setPageScale(pageScale / 1.2)
  }

  return (
    <Box
      width={'100%'}
      height={'100%'}
      overflow={'auto'}
      ref={(n) => {
        canvasRef.current = n
        scrollContainer.ref(n)
      }}
    >
      <AutoSaveModal />
      <ConfirmNavigation isBlocked={isBlocking} />

      <ToolbarComponent
        totalPages={totalPages}
        pageNum={pageNum}
        setPageNum={setPageNum}
        handlePageScale={handlePageScale}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        multiSelectedData={multiSelectedData}
        setMultiSelectedData={setMultiSelectedData}
        setClipBoard={setClipBoard}
        clipBoard={clipBoard}
        setBlocking={setIsBlocking}
      />

      <div className='ResizableBoxContainer ResizableBoxContainerLeft'>
        <ResizableBox width={400} minConstraints={[200, 200]} axis='x' className='ResizableBox'>
          <>
            <ResizableBox height={300} minConstraints={[200, 150]} axis='y'>
              <PanelBox
                title={t('designer.templateTreeView.template')}
                onSettings={() => setSelectedData(domTemplate.documentInfo)}
                loading={templateVersionStore.isLoading}
              >
                <DOMTemplateTreeView
                  selectedUID={selectedData}
                  setSelectedData={handleSelectData(
                    selectedData,
                    setSelectedData,
                    multiSelectedData,
                    setMultiSelectedData
                  )}
                  multiSelectedUID={multiSelectedData.map((data) => data.id)}
                  handleKeyDown={handleKeyDown}
                />
              </PanelBox>
            </ResizableBox>
            {selectedData && (
              <PanelBox
                title={t('designer.dataTypes.' + selectedData.type) + ' ' + t('designer.propertiesView.properties')}
                minimize
                onClose={() => {
                  setSelectedData(null)
                }}
              >
                <PropertiesViewer data={selectedData} spots={domTemplate?.spots} />
              </PanelBox>
            )}
          </>
        </ResizableBox>
      </div>

      {((domRoot && domRoot.length) || debugTree) && (
        <div className='ResizableBoxContainer ResizableBoxContainerRight'>
          <ResizableBox width={350} minConstraints={[200, 200]} axis='x' resizeHandles={['w']} className='ResizableBox'>
            <>
              {domRoot.length != 0 && (
                <ResizableBox height={400} minConstraints={[200, 80]} axis='y'>
                  <PanelBox
                    minimize
                    title={
                      <Stack
                        direction={'row'}
                        sx={{
                          Button: {
                            color: '#696b6c',
                            fontSize: '1rem',
                            fontWeight: 700,
                            textTransform: 'none',
                          },
                          'Button:disabled': { color: 'black' },
                        }}
                      >
                        <Button disabled={!showParserResult} onClick={() => setShowParserResult(false)}>
                          {t('designer.uaResult.uaResult')}
                        </Button>
                        <Button disabled={showParserResult} onClick={() => setShowParserResult(true)}>
                          {t('designer.uaResult.pdfParser')}
                        </Button>
                      </Stack>
                    }
                    loading={domRoot[0] === -1}
                  >
                    {!showParserResult ? (
                      <DOMTreeView data={domRoot} onSelect={setSelectedProducedData} setPageNumber={setPageNum} />
                    ) : (
                      <PDFParserTreeView onSelect={setSelectedProducedData} setPageNumber={setPageNum} />
                    )}
                  </PanelBox>
                </ResizableBox>
              )}
              {debugTree && (
                <PanelBox minimize title={t('designer.debug.debug')} loading={debugTree === -1}>
                  <DOMTreeView data={debugTree} onSelect={setSelectedProducedData} setPageNumber={setPageNum} />
                </PanelBox>
              )}
            </>
          </ResizableBox>
        </div>
      )}
      <Box
        sx={{
          width: '250%',
          height: '250%',
          position: 'relative',
          background: '#f3f5f7',
          backgroundPosition: '-19px -19px',
          backgroundSize: '' + 24 * pageScale + 'px ' + 24 * pageScale + 'px',
          backgroundImage: 'radial-gradient(rgb(224, 224, 224) ' + (0.2 + pageScale) + 'px, transparent 0)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: '6px',
          }}
        >
          <div onKeyUp={handleKeyDown} tabIndex={0}>
            <Document
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              loading={
                <Skeleton width={595} height={842} sx={{ background: '#ffffff6e', opacity: 0.5, transform: 'none' }} />
              }
              noData={
                <Skeleton width={595} height={842} sx={{ background: '#ffffff6e', opacity: 0.5, transform: 'none' }} />
              }
            >
              <Box sx={{ position: 'relative', display: 'flex' }}>
                {domTemplate && (
                  <CanvasView
                    selectedUID={selectedData ? selectedData.id : ''}
                    multiSelectedUID={multiSelectedData.map((data) => data.id)}
                    setSelectedData={handleSelectData(
                      selectedData,
                      setSelectedData,
                      multiSelectedData,
                      setMultiSelectedData
                    )}
                    scale={pageScale}
                    selectedProducedData={selectedProducedData}
                  />
                )}
                {selectedProducedData && (
                  <UABoundBoxView selectedProducedData={selectedProducedData} scale={pageScale} />
                )}
                <Page pageNumber={pageNum} scale={pageScale} loading={<></>} renderTextLayer={false} />
              </Box>
            </Document>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default Designer

