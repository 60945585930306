import React, { useEffect, useState } from 'react'
import { Button, IconButton, Skeleton, Stack, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import TemplateManager from '../components/Template/TemplateManager'
import ProjectPropertiesModal from '../components/Project/ProjectPropertiesModal'
import ProjectDeleteModal from '../components/Project/ProjectDeleteModal'
import { useTranslation } from 'react-i18next'

const Project = () => {
  const { t } = useTranslation()

  const { accountId, projectId } = useParams()
  const { accountStore, projectStore, templateStore } = useRootStore()
  const [editProjectModalOpen, setEditProjectModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId && projectId) {
      accountStore.loadCurrent(accountId)
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadCurrent(projectId)
      templateStore.clearCurrent()
    }
  }, [accountId, projectId])

  return (
    <>
      <Stack direction={'row'} alignItems='center' justifyContent={'between'}>
        <h1>{projectStore.currentProject ? projectStore.currentProject.name : <Skeleton width={300} />}</h1>

        <Stack
          direction={'row'}
          sx={{
            ml: 'auto',
          }}
        >
          <Tooltip title={t('projects.projectProperties.editProject')}>
            <span>
              <IconButton
                onClick={(e) => {
                  setEditProjectModalOpen(true)
                  e.stopPropagation()
                }}
                disabled={!projectStore.currentProject}
              >
                <MoreVertIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('projects.projectProperties.deleteProject')}>
            <span>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleteModalOpen(true)
                }}
                disabled={!projectStore.currentProject}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>

      <TemplateManager editable />
      <ProjectDeleteModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} />
      <ProjectPropertiesModal
        addProjectModalOpen={editProjectModalOpen}
        setAddProjectModalOpen={setEditProjectModalOpen}
      />
    </>
  )
}

export default observer(Project)

