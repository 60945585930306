import React, { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'
import eventEmitter from './eventEmitter'

export const CustomSnackbar = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('info')

  useEffect(() => {
    eventEmitter.on('showSnackbar', ({ message, severity }) => {
      setMessage(message)
      setSeverity(severity)
      setOpen(true)
    })

    return () => {
      eventEmitter.removeAllListeners('showSnackbar')
    }
  }, [])

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ maxWidth: '1200px' }}>
        {' '}
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar

