import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1b3048',
    },
    secondary: {
      main: '#466586',
    },
    text: {
      primary: '#000000',
      secondary: 'gray',
    },
    background: {
      default: '#f3f5f7',
      paper: '#fff',
    },
    success: {
      main: '#5e8660',
    },
    info: {
      main: '#6b6969',
    },
    warning: {
      main: '#ffc107',
    },
    error: {
      main: '#ee3d3d',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
})
