import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { Button, Container, Modal, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRootStore } from '../../providers/RootStoreProvider'

interface TemplateVersionModal {
  modalOpen: boolean
  setmodalOpen: (data: boolean) => void
  version: string
  action: ((data?: any) => void) | null
  message?: boolean
}

const TemplateVersionModal: React.FC<TemplateVersionModal> = ({
  modalOpen,
  setmodalOpen,
  version,
  action,
  message,
}) => {
  const { t } = useTranslation()
  const { templateVersionStore } = useRootStore()

  const [messageText, setMessageText] = useState<string>('')

  const handleClose = useCallback(() => {
    setmodalOpen(false)
  }, [setmodalOpen])

  const handle = useCallback(async () => {
    if (!templateVersionStore.currentTemplateVersion) return

    try {
      action !== null && (message ? await action(messageText) : await action())
    } finally {
      setmodalOpen(false)
      setMessageText('')
    }
  }, [modalOpen, action, version, messageText])

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Container
        maxWidth='sm'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          minWidth: '500px',
        }}
      >
        <Stack alignItems='center' textAlign='center' spacing={2}>
          <Typography variant='h5' fontWeight={500}>
            {t('templateVersions.modal.' + version)}
          </Typography>
          <Typography variant='body1' color="text.secondary">
            {t('templateVersions.modal.' + version + 'Help')}
          </Typography>
        </Stack>
        {message && (
          <TextField
            fullWidth
            label='Message'
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            sx={{ mt: 2 }}
          />
        )}
        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button
            color={version === 'delete' ? 'error' : 'success'}
            variant='contained'
            onClick={handle}
            sx={{
              minWidth: 100
            }}
          >
            {t('templateVersions.actions.' + version)}
          </Button>
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{
              minWidth: 100
            }}
          >
            {t('templateVersions.modal.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(TemplateVersionModal)
