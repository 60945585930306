import React from 'react'
import {
  Breadcrumbs,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  LinkProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useLocation, useNavigate, useParams, Link as RouterLink } from 'react-router-dom'

import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import SampleDocument from '../domain/SampleDocument'
import { useTranslation } from 'react-i18next'
import UserMenu from './UserMenu'
import { stringAvatar } from './StringAvatar'

import { ListItemProps } from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import TemplateVersion from '../domain/TemplateVersion'

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />
}

const MainLayoutHeader = ({
  designer,
  produceResult,
}: {
  designer?: boolean
  produceResult?: (sampleDocumentId: string) => void
}) => {
  const { t } = useTranslation()

  const { accountId, projectId, templateId, templateVersionId, sampleDocuementId } = useParams()
  const { accountStore, projectStore, templateStore, templateVersionStore, sampleDocumentStore } = useRootStore()
  const { pathname } = useLocation()

  const pathSegments = pathname.split('/').filter(Boolean)
  const imageTypeChar =
    accountStore.currentAccount && accountStore.currentAccount.logo && accountStore.currentAccount.logo.charAt(0)
  const imageType =
    imageTypeChar && imageTypeChar === '/' ? 'jpg' : imageTypeChar === 'i' ? 'png' : imageTypeChar === 'R' ? 'gif' : ''

  const navigate = useNavigate()

  const items = [
    {
      condition: pathname.includes('transactions'),
      to: `/transactions`,
      text: t('menu.transactions'),
    },
    {
      condition: pathname.includes('home'),
      to: `/accounts/${accountStore.currentAccount?.id}/home`,
      text: t('menu.home'),
    },
    {
      condition: pathSegments.includes('account') && pathSegments.includes('accounts'),
      to: `/accounts/${accountStore.currentAccount?.id}/account`,
      text: t('menu.account'),
    },
    {
      condition: pathname.includes('projects') && !designer,
      to: `/accounts/${accountId}/projects`,
      text: t('menu.projects'),
    },
    {
      condition: pathname.includes('projects') && projectStore.currentProject,
      to: `/accounts/${accountId}/projects/${projectStore.currentProject?.id}`,
      text: projectStore.currentProject?.name,
    },
    {
      condition:
        pathname.includes('projects') && projectStore.currentProject && pathname.includes('templates') && !designer,
      to: `/accounts/${accountId}/projects/${projectId}`,
      text: t('menu.templates'),
    },
  ]

  return (
    <Stack
      role='navigation'
      direction={'row'}
      sx={{
        ...(designer && {
          position: 'fixed',
          zIndex: 1,

          top: 0,
          left: 0,
          width: '100%',
        }),
        justifyContent: 'space-between',
        p: '16px 24px',
      }}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        sx={{
          fontSize: 15,
          '.MuiBreadcrumbs-separator': {
            ml: '4px',
            mr: '4px',
          },
        }}
        aria-label='breadcrumb'
      >
        {accountStore.currentAccount && (
          <LinkRouter to={'/accounts/' + accountId + '/home'} underline='none'>
            <Avatar
              src={`data:image/${imageType};base64,${accountStore.currentAccount.logo}`}
              {...stringAvatar(accountStore.currentAccount.name)}
            />
          </LinkRouter>
        )}

        {items
          .filter((item) => item.condition)
          .map((item, index, array) =>
            index === array.length - 1 && !designer && !templateVersionStore.currentTemplateVersion ? (
              <Typography key={'BreadCrumb-' + index} sx={{ color: 'text.primary' }} aria-current='page'>
                {item.text}
              </Typography>
            ) : (
              <LinkRouter to={item.to} key={'BreadCrumb-' + index} underline='hover' color='inherit'>
                {item.text}
              </LinkRouter>
            )
          )}

        {pathname.includes('projects') &&
          projectStore.currentProject &&
          pathname.includes('templates') &&
          templateStore.currentTemplate && (
            <Stack direction={'row'} alignItems={'center'}>
              <LinkRouter
                to={designer ? `/accounts/${accountId}/projects/${projectId}/templates/${templateId}` : ''}
                underline={designer ? 'hover' : 'none'}
                sx={{ color: designer ? 'inherit' : 'text.primary' }}
              >
                {templateStore.currentTemplate?.name}
              </LinkRouter>
              {templateVersionStore.currentTemplateVersion && (
                <FormControl>
                  <InputLabel
                    htmlFor={'select-label-' + t('menu.templateVersion')}
                    sx={{
                      display: 'none',
                    }}
                  >
                    {t('menu.templateVersion')}
                  </InputLabel>
                  <Select
                    inputProps={{ id: 'select-label-' + t('menu.templateVersion') }}
                    sx={{
                      fontSize: 15,
                      maxWidth: '200px',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                      '.MuiSelect-outlined': {
                        p: 1,
                      },
                      color: designer ? 'inherit' : 'text.primary',
                      mr: '-14px',
                    }}
                    onChange={(event) => {
                      designer && sampleDocumentStore.currentSampleDocument
                        ? navigate(
                            `/accounts/${accountId}/projects/${projectId}/templates/${templateId}/versions/${event.target.value}/sampleDocuments/${sampleDocumentStore.currentSampleDocument.id}/designer`
                          )
                        : navigate(
                            `/accounts/${accountId}/projects/${projectId}/templates/${templateId}/versions/${event.target.value}`
                          )
                    }}
                    value={templateVersionStore.currentTemplateVersion.id}
                  >
                    {templateVersionStore.templateVersions.map((version: TemplateVersion, versionIndex: number) => (
                      <MenuItem key={`version-${versionIndex}`} value={version.id}>
                        {'v' + version.versionNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
          )}

        {designer && sampleDocumentStore.currentSampleDocument && (
          <FormControl>
            <InputLabel
              htmlFor={'select-label-' + t('menu.sampleDocument')}
              sx={{
                display: 'none',
              }}
            >
              {t('menu.sampleDocument')}
            </InputLabel>
            <Select
              inputProps={{ id: 'select-label-' + t('menu.sampleDocument') }}
              sx={{
                fontSize: 15,

                maxWidth: '170px',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '.MuiSelect-outlined': {
                  p: 1,
                },
                '.MuiMenu-paper': {},
              }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    fontSize: 15,
                  },
                },
              }}
              onChange={(event) => {
                navigate(
                  `/accounts/${accountId}/projects/${projectId}/templates/${templateId}/versions/${templateVersionId}/sampleDocuments/${event.target.value}/designer`
                )
              }}
              value={sampleDocumentStore.currentSampleDocument.id}
            >
              {sampleDocumentStore.sampleDocuments.map((sample: SampleDocument, sampleIndex: number) => (
                <MenuItem key={`samplePDF-${sampleIndex}`} value={sample.id}>
                  {sample.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Breadcrumbs>
      <UserMenu />
    </Stack>
  )
}

export default observer(MainLayoutHeader)
